import { Delete, Edit } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import ConfirmationModal from '../Modals/ConfirmationModal';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import MatTable from '../Tables/MatTable';
import { deleteFaq, getFAQSListing } from '../../Services/cmsService';
import { cmsItemsSelector } from '../../redux/cms';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import AddEditFAQSModal from '../Modals/AddEditFAQSModal';
import parse from 'html-react-parser'
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';
import { adminAddLogs } from '../../Services/commonService';

const limit = 10;
//BASE_IMAGE_URL
const column = [
  {
    label: 'Question',
    id: 'question',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 300, width: "max-content" }}>{columnData}</span>),
  },
  {
    label: 'Answer',
    id: 'answer',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 500, width: "max-content" }}>{parse(columnData)}</span>)
  },
  {
    label: 'Status',
    id: 'status',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content" }}>{columnData}</span>)
  }
];

function FAQPage() {
  const [tableListing, setTableListing] = useState([])
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, faqsData } = useSelector(cmsItemsSelector);
  const dispatch = useDispatch();
  const [addFAQSModal, setAddFAQSModal] = useState(false);
  const [editFAQSModal, setEditFAQSModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);

  const navigate = useNavigate()
  const location = useLocation();

  const handleAdd = (value = false) => {
    setAddFAQSModal(value)
    if (!value) {
      setSelectedData(null)
    }
  }
  const handleEdit = (value = false) => {
    setEditFAQSModal(value);
    if (!value) {
      setSelectedData(null)
    }
  }

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      ...allParams,
      ...params
    }
    dispatch(getFAQSListing(payload))
    setPage(Number(payload.page))
  }

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params);
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  }, [allParams])

  //making data format for table input
  useEffect(() => {
    if (faqsData?.items?.data?.length >= 0) {
      setTableListing(faqsData?.items?.data)
    }
  }, [faqsData.items]);

  const handleConfirmDeleteModal = async (value) => {
    if (value && selectedData) {
      const resp = await deleteFaq({ faq_id: selectedData?.faq_id });
      if (resp) {
        let payloadForLogs = {
          action: action?.delete,
          module: modulesNames?.cms,
          description: logsMessagesList?.cms?.faqDelete,
        };
        await adminAddLogs(payloadForLogs);
        getListing();
      }
    } else {
      setSelectedData(null)
    }
  }

  const handleCofirmModalOpen = (value) => {
    setConfirmDeleteModal(value);
  }

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: "flex" }}>
          <CustomButton className="noMinWidth buttonDense" onClick={() => {
            setSelectedData(data)
            handleEdit(true)
          }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText' }}><Edit /></CustomButton>
          <CustomButton className="noMinWidth buttonDense" onClick={() => {
            handleCofirmModalOpen(true)
            setSelectedData(data)
          }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}><Delete /></CustomButton>
        </div>
      </>
    );
  };

  return (
    <CustomBox padding={0}>
      <MatTable
        loading={faqsData?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: "",
            end_date: "",
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        page={page}
        perPage={limit}
        total={faqsData?.items?.totalCount || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        toolBarButton={() => {
          return <>
            <CustomButton onClick={() => { handleAdd(true) }} color={'buttonPrimary'} sx={(theme) => ({
              padding: '10px 10px', marginRight: 1,
              [theme.breakpoints.down("md")]: {
                flex: 1,
              }
            })}>Add FAQ</CustomButton>
          </>
        }}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        rangeDateFilter
        tdStyle={{ verticalAlign: 'top' }}
      />
      {addFAQSModal && <AddEditFAQSModal open={addFAQSModal} getListing={getListing} type={'add'} handleOpen={handleAdd} />}
      {confirmDeleteModal && <ConfirmationModal open={confirmDeleteModal} handleConfirm={handleConfirmDeleteModal} setOpen={handleCofirmModalOpen} />}
      {editFAQSModal && <AddEditFAQSModal open={editFAQSModal} getListing={getListing} handleOpen={handleEdit} type={'edit'} data={selectedData} />}
    </CustomBox>
  );
}

export default FAQPage;
