import { Delete, Edit } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { formatDate, getObjectSubset, statusText } from '../../lib/helper';
import { categoryItemsSelector } from '../../redux/category';
import { OrderFollowUpsData, followUpsByOrderIdsListings, saleFollowUpListingsNoRedux } from '../../Services/CRMFollowups';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import MatTable from '../Tables/MatTable';
import EditFollowupHistoryEntryModal from '../Modals/EditFollowupHistoryEntryModal';
import { followUpsItemSelector } from '../../redux/followUps';
import CreateOrderFollowUps from './CreateOrderFollowUps';
import Divder from '../CommonComp/Divder';
import { decrypt } from '../../lib/helper';
import CreateMultipleOrderFollowUps from './CreateMultipleOrderFollowUps';
import AllFilters from '../Order/AllFilters';

const limit = 5;


const column = [
  {
    label: 'Next Follow Ups Date',
    id: 'next_followup_date',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {formatDate(columnData, 'mon dd yyyy')}
      </span>
    ),
  },
  {
    label: 'Description',
    id: 'description',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Create Date',
    id: 'created_at',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {formatDate(columnData)}
      </span>
    ),
  },
];

function MasterListMultipleFollowUps({ order_ids }) {
  const [tableListing, setTableListing] = useState([])
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1);
  const [selectedData, setSelectedData] = useState(null);
  const [allParams, setAllParams] = useState({});
  const { subCategoryList } = useSelector(categoryItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { orderFollowUpData,companyInvoiceDropDown } = useSelector(followUpsItemSelector);
  let orderId = decrypt('order_id', searchParams.get('order_ids')) ;
  let search_by=searchParams?.get('search_by');
  let search='';
  if(search_by){
    if(search_by=='user_name')search=searchParams?.get('user_name');
    if(search_by=='order_id')search=searchParams?.get('order_id');
    if(search_by=='invoice_id')search=searchParams?.get('invoice_id');
    if(search_by=='short_company_name')search=searchParams?.get('short_company_name');
    if(search_by=='payment_type')search=searchParams?.get('payment_type');
    if(search_by=='ordered_by')search=searchParams?.get('ordered_by');
    if(search_by=='client')search=searchParams?.get('client');
    if(search_by=='purchase_order')search=searchParams?.get('purchase_order');
  }
  if (!orderId) {
    navigate('/crm', { replace: true })
  }
  let selectArrayItems=[];
  let dropDownArray=[];

  if(orderId){
  let orderArray=orderId?.split(",")?.map((id)=>({label:id,value:id}));
  orderArray?.unshift({label:'All Order Ids',value:orderId});
  selectArrayItems=[...orderArray];
  dropDownArray=JSON.parse(localStorage.getItem("dropDownArray"));
  dropDownArray?.unshift({label:'All Invoice Ids',value:orderId});
  }


  const [filtersArray, setFiltersArray] = useState([
    {
      label: 'Search By',
      id: 'search_by',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: dropDownArray,
      props: {},
      isVisible: true,
    },
  ]);

  const handleFilter = (data, allDatavalues, dataWithValue) => {
    
    getListing(
      {
        page: String(1),
        ...dataWithValue,
      },
      true,
    );
    setAllParams({
      page: String(1),
      ...dataWithValue,
    });
  };
  const handleClear = () => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    delete data?.search_by;
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0].isVisible = true;
      newState[0].value = '';
      return newState;
    });
    getListing(data,true,true);
  };

  const getListing = async (params = {},second,action=false) => {
    const payload = {
      page: String(1),
      limit: String(limit),
      order_id: orderId,

      ...allParams,
      ...params,
    };
    if(action){
      delete payload?.search_by
    };
    dispatch(followUpsByOrderIdsListings(payload));
    setPage(Number(payload.page));
  };

  useEffect(() => {
    getListing();
  }, []);
  
  useEffect(() => {
    getFollowUpdata();
  }, [orderId])

  useEffect(() => {
    if (orderFollowUpData?.items?.data?.length >= 0) {
      setTableListing(orderFollowUpData?.items?.data);
    }
  }, [orderFollowUpData.items]);

  const getFollowUpdata = async () => {
    let payload = { order_id: orderId };
    if(search_by){
      payload['search_by']=search_by;
      if(search)payload['search']=search;
    }
    let resp = await OrderFollowUpsData(payload);
    let addionalInfo = { 'order_id': orderId }
    let invoiceIds=resp?.data?.data?.results?.map((inv=>inv?.invoice?.invoice_id))?.join(",")
    let respAll = { ...resp?.data?.data?.user, ...addionalInfo,remarks:resp?.data?.data?.first_user_remark
    };
    respAll['invoice_id']=invoiceIds;
    setSelectedData(respAll)
  }
  const handleEditModal = (value = false) => {
    setOpen(value)
    if (!value) {
      setSelectedData(null)
    }
  }
  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: "flex" }}>
          <CustomButton className="noMinWidth buttonDense" toolTipTittle={'Edit'} onClick={() => {
            setSelectedData({
              ...data,
              order_id: tableListing?.order_id,
              daily_sale_id: dailySaleId
            })
            handleEditModal(true)
          }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText' }}><Edit /></CustomButton>
        </div>
      </>
    );
  };


  return (
    <div>  
      <CustomBox padding={1}>
        <CreateMultipleOrderFollowUps selectedData={selectedData} getListing={getListing} />
      </CustomBox>

      <Divder spacing={1} />
      <CustomBox padding={0}>
        <MatTable
          loading={loading}
          showCheckbox={false}
          columns={column}
          data={tableListing || []}
          onApplyFilter={(data = {}) => {
            getListing({
              page: String(1),
              start_date: "",
              end_date: "",
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
            if (Object.keys(data).length > 0) {
              setAllParams({
                ...allParams,
                page: String(1),
                ...getObjectSubset(data, 'start_date', 'end_date'),
              });
            } else {
              setAllParams({
                page: String(1),
              });
            }
          }}
          page={page}
          perPage={limit}
          total={orderFollowUpData?.items?.totalCount || 0}
          setPage={setPage}
          handleSearch={(searchTerm) => {
            getListing({
              page: String(1),
              search: searchTerm
            })
            setAllParams({
              ...allParams,
              page: String(1),
              search: searchTerm
            })
          }}
          handlePageChange={(newPage) => {
            getListing({
              page: String(newPage)
            })
            setAllParams({
              ...allParams,
              page: String(newPage)
            })
          }}

          columnWiseData={[
            {
              id: 'order_id',
              value: tableListing?.order_id || "-",
            }
          ]}
          toolbarEnable={true}
          pagination={true}
          rangeDateFilter
          disableSearch
          customFilter={() => (
            <AllFilters
              filtersArray={filtersArray}
              handleClick={handleFilter}
              handleClear={handleClear}
            />
          )}
        />
        {open && <EditFollowupHistoryEntryModal open={open} handleOpen={handleEditModal} selectedData={selectedData} getListing={getListing} />}

      </CustomBox>
    
    </div>
  );
}

export default MasterListMultipleFollowUps;


