import axios from 'axios';
import { toast } from 'react-toastify';
import ApiConfig from '../config/ApiConfig';
import { STATUS_200, STATUS_300 } from '../data/constants';
import { deleteApiPayload, errorMessage } from '../lib/helper';
export const deleteAdminUsersLogs = async (payload) => {
    if (payload !== undefined) {
      try {
       // const resp = await axios.delete(`${ApiConfig.deleteAdminLogById}`, payload);
        const resp = await axios.delete(
            `${ApiConfig.deleteAdminLogById}`,
            deleteApiPayload(payload),
          );
        if (resp?.data?.status >= STATUS_200 && resp?.data?.status < STATUS_300) {
          toast.success(resp.data?.message, {
            autoClose: 2500,
          });
          return true;
        } else {
          throw resp;
        }
      } catch (e) {
        toast.error(errorMessage(e), {
          autoClose: 2500,
        });
        return false;
      }
    }
  };