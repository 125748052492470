import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';

import { DATA_LIMIT } from '../../data/constants';
import {
  editVerticalHorizontalData,
} from '../../Services/cmsService';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';
import { adminAddLogs } from '../../Services/commonService';


function EditVerticalOrHorizontal({
  data,
  open,
  handleOpen,
  setPage,

  handleLast,
}) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true);
    let resp = await editVerticalHorizontalData(values);
    if (resp) {
      let payloadForLogs = {
        action: action?.update,
        module: modulesNames?.cms,
        description: logsMessagesList?.cms?.verticalImageDimensionUpdated
      };
      await adminAddLogs(payloadForLogs);
      resetForm({
        sample_image_id :'',
        fileformat:'',
        image_type:  '',
        resolution: '',
        downloadsize: '',
        dimension:  '',
        size:'',
      });
      handleLast();
      handleOpen(false);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      sample_image_id :data?.id,
      fileformat: data?.fileformat || '',
      image_type: data?.image_type || '',
      resolution: data?.resolution || '',
      downloadsize: data?.downloadsize|| '',
      dimension: data?.dimension|| '',
      size:data?.size ||'',
      // type:'Horizontal'
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      sample_image_id : Yup.string().required('Id is required.'),
      size: Yup.string().required('Size is required.'),
      fileformat: Yup.string().required(
        'file format File Size is required.',
      ),
      image_type: Yup.string().required('Image Type is required.'),
      resolution: Yup.string().required('Resolution is required.'),
      downloadsize: Yup.string().required('Download size is required.'),
      dimension: Yup.string().required('Dimension size is required.'),
      // type: Yup.string().required('Type is required.'),
    }),
    onSubmit: handleConfirm,
  });
  
  return (
    <div>
      <Dialog
        maxWidth={'md'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          Edit Vertical/Horizontal Image Dimesnion
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            // sx={{
            //   width: 400
            // }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="size"
                  label="Size"
                  placeholder="Size"
                  value={formik.values.size}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.size && Boolean(formik.errors.size)
                  }
                  helperText={
                    formik.touched.size && formik.errors.size
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="fileformat"
                  label="File Format"
                  placeholder="File Format"
                  value={formik.values.fileformat}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.fileformat &&
                    Boolean(formik.errors.fileformat)
                  }
                  helperText={
                    formik.touched.fileformat &&
                    formik.errors.fileformat
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="image_type"
                  label="Image Type"
                  placeholder="Image Type"
                  value={formik.values.image_type}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.image_type && Boolean(formik.errors.image_type)}
                  helperText={formik.touched.image_type && formik.errors.image_type}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="resolution"
                  label="Resolution"
                  placeholder="Resolution"
                  value={formik.values.resolution}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.resolution && Boolean(formik.errors.resolution)}
                  helperText={formik.touched.resolution && formik.errors.resolution}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="downloadsize"
                  label="Download Size"
                  placeholder="downloadsize"
                  value={formik.values.downloadsize}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.downloadsize && Boolean(formik.errors.downloadsize)
                  }
                  helperText={
                    formik.touched.downloadsize && formik.errors.downloadsize
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="dimension"
                  label="Dimension"
                  placeholder="Dimension"
                  value={formik.values.dimension}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.dimension && Boolean(formik.errors.dimension)
                  }
                  helperText={formik.touched.dimension && formik.errors.dimension}
                />
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Save
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    download_time_id: '',
                    file_size: '',
                    compressed_file_size: '',
                    time: '',
                    isdn: '',
                    broadband: '',
                    file_format: '',
                  });
                  handleOpen(false);
                }}
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
        {/* <DialogActions>
					<CustomButton color={'buttonPrimary'} type="submit" loading={loading}>Upload</CustomButton>
					<CustomButton onClick={() => handleOpen(false)}>Close</CustomButton>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}

export default EditVerticalOrHorizontal;
