import React, { useEffect, useState } from 'react';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import { Box, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ImageUploadBanners from './ImageUploadBanners';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import UploadImageModal from '../Modals/UploadImageModal';
import { cmsItemsSelector } from '../../redux/cms';
import {
  editAddCMS,
  getBannersInfo,
  getCMSInfo,
} from '../../Services/cmsService';
import { toast } from 'react-toastify';
import AllConstant from '../../AllConstant';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CustomInput from '../CommonComp/CustomInput/CustomInput';

function BannersCrousel({}) {
  const { bannersImagesData, cmsData } = useSelector(cmsItemsSelector);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  let cmsDataHome = cmsData
    ? cmsData?.items?.length > 0 && JSON?.parse(cmsData?.items[0]?.key_value)
    : [];

  const handleOpen = (value = false) => {
    setOpen(value);
  };

  const getListing = async (params = {}) => {
    dispatch(getBannersInfo());
  };

  const getBannerLink = async (params = {}) => {
    let payload = { pagename: 'homePage' };
    dispatch(getCMSInfo(payload));
  };

  useEffect(() => {
    getListing();
    getBannerLink();
  }, []);

  const handleModalOpenAndLimit = () => {
    if (bannersImagesData?.items?.message?.length >= 5) {
      toast.error(AllConstant?.alertMessages?.bannerImageMessage, {
        autoClose: 2500,
      });
    } else setOpen(true);
  };

  const handleConfirm = async (values, { resetForm }) => {
    let newObj = { ...cmsDataHome, BannerLink: values };
    setLoading(true);
    let str1 = JSON.stringify(newObj);
    let obj = { pagename: 'homePage', pagedata: str1 };
    let resp = await editAddCMS(obj);
    if (resp) {
      getBannerLink();
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      bannerLink: cmsDataHome?.BannerLink?.bannerLink || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      bannerLink: Yup?.string().required('Banner link is Required!'),
    }),
    onSubmit: handleConfirm,
  });

  const makeBannerLink = () => {
    let link = `https://imagesbazaar.com/${formik?.values?.bannerLink}`;
    return link;
  };

  return (
    <>
      <CustomBox padding={3} sx={{ minHeight: 200 }}>
        <Grid container spacing={2}>
          <Grid item xs="12" sm={6} lg={6}>
            <Typography
              variant="h5"
              sx={{ marginBottom: '2px', marginTop: '2px', marginLeft: '10px' }}
            >
              You have uploaded {bannersImagesData?.items?.message?.length} out
              of 5
            </Typography>
            <Typography
              variant="h5"
              sx={{ marginBottom: '2px', marginTop: '2px', marginLeft: '10px' }}
            >
              Recommended Size :{' '}
              <span style={{ fontWeight: '700' }}>1920 X 400</span>
            </Typography>
          </Grid>
          <Grid item xs="12" sm={6} lg={6}>
            <Box display="flex" justifyContent="flex-end">
              <CustomButton
                type="button"
                onClick={(e) => {
                  handleModalOpenAndLimit();
                }}
                loading={bannersImagesData?.loading}
                color={'buttonPrimary'}
                sx={{ padding: '8px 20px' }}
              >
                Upload New Image
              </CustomButton>
            </Box>
          </Grid>

          {bannersImagesData?.items?.message?.map((item, index) => {
            return (
              <Grid item xs={12} key={item?.banner_image_id}>
                <ImageUploadBanners
                  item={item}
                  parentKey={'bannersImages'}
                  total={bannersImagesData?.items?.message?.length}
                  handleLast={getListing}
                />
              </Grid>
            );
          })}
        </Grid>
      </CustomBox>
      <CustomBox padding={3} marginTop={1} sx={{ minHeight: 100 }}>
        <Box
          noValidate
          component="form"
          onSubmit={formik.handleSubmit}
          marginTop={2}
          marginLeft={2}
        >
          <Grid container spacing={2}>
            <Typography
              variant="h5"
              sx={{
                marginBottom: '10px',
                marginTop: '2px',
                marginLeft: '10px',
              }}
            >
              Banner Link:{' '}
              <span style={{ color: '#3498db' }}>{makeBannerLink()}</span>
            </Typography>
          </Grid>
          <Grid item xs="12" sm={6} lg={6}>
            <Grid item xs={12} md={8} lg={12}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="bannerLink"
                label="Banner Link"
                placeholder="type..."
                value={formik.values.bannerLink}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                multiline
                rows={1}
                error={
                  formik.touched.bannerLink && Boolean(formik.errors.bannerLink)
                }
                helperText={
                  formik.touched.bannerLink && formik.errors.bannerLink
                }
              />
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end">
            <CustomButton
              type="submit"
              // onClick={(e) => {
              //   handleModalOpenAndLimit();
              // }}
              loading={bannersImagesData?.loading}
              color={'buttonPrimary'}
              sx={{ padding: '8px 20px', marginTop: '10px' }}
            >
              Update Banner Link
            </CustomButton>
          </Box>
        </Box>
      </CustomBox>
      {open && (
        <UploadImageModal
          open={open}
          handleOpen={handleOpen}
          handleLast={getListing}
        />
      )}
      {/* {open && (
        <UpdateBannerLink
          open={bannerLink}
          handleOpen={handleBannerOpen}
          handleLast={getListing}
        />
      )} */}
    </>
  );
}

export default BannersCrousel;
