import { Delete, Edit, EditAttributes } from '@material-ui/icons';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useEffect, useState } from 'react';
import { Box, Button, ButtonGroup, Divider, FormHelperText, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CheckboxField from '../CommonComp/CustomInput/CheckboxField';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import AddCMSkeyword from '../Modals/AddCMSkeyword';
import { editAddCMS } from '../../Services/cmsService';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';
import { adminAddLogs } from '../../Services/commonService';

function PopularSearch({ searchKeyword, cmsDataHome, handleLast }) {
  const [loading, setLoading] = useState(false);
  const [searchKeywordCopy, setSearchKeyword] = useState(searchKeyword);
  const [searchResult, setSearchResult] = useState();
  const [addModal, setAddModal] = useState(false);


  useEffect(() => {
    getListing();
  }, [searchKeyword])

  const getListing = () => {
    // let payload = { pagename: 'homePage' } 
    // dispatch(getCMSInfo(payload));
    setSearchKeyword(searchKeyword);
    setSearchResult(searchKeyword?.content?.map(a => ({ key: a, checked: true })));
  }

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true);
    let filteredArray = searchResult?.filter(a => a?.checked)?.map(b => b?.key)
    let cmsObj = { ...cmsDataHome, searchData: { heading: values?.heading, content: filteredArray } }
    let str1 = JSON.stringify(cmsObj);
    let obj = { pagename: 'homePage', pagedata: str1 }
    let resp = await editAddCMS(obj);
    if (resp) {
      let payloadForLogs = {
        action: action?.update,
        module: modulesNames?.cms,
        description: logsMessagesList?.cms?.searchKeywordUpdated,
      };
      await adminAddLogs(payloadForLogs);
      handleLast();
      setLoading(false);
    }
  };

  const handleAdd = (value = false) => {
    setAddModal(value);
  }

  const handleCheckBox = (e, label, index) => {
    let arr = [...searchResult];
    arr[index]['checked'] = e.target.checked;
    setSearchResult(arr);
  }

  const formik = useFormik({
    initialValues: {
      heading: searchKeywordCopy?.heading,
      content: searchResult?.filter(a => a?.checked),
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      heading: Yup.string().required('Heading is required'),
      content: Yup.array().min(16, "Minimum 16 Keyword required!").max(21, "You can Add upto 21 Keyword!").required('Required')
    }),
    onSubmit: handleConfirm,
  });

  const handleKeyword = (value) => {
    let index=searchResult?.findIndex(sr=>sr?.key===value?.search)
    if(index<0){
      setSearchResult([...searchResult, { key: value?.search, checked: false }])
   
    }
    handleAdd(false);

  }
  return (
    <>
      <Box
        noValidate
        component="form"
        // sx={{
        //   width: 800
        // }}
        marginTop={6}
        marginLeft={2}
        onSubmit={formik.handleSubmit}
      >
        <Typography variant='h5' mb={2} >Keyword Suggestion Section</Typography>
        <Grid container spacing={2} my={2}>
          {/* {buttonList?.map((src, index) => {
            return <Grid item xs={12} sm={4} md={2}>
              <span mb={2}> {src}</span>  <EditIcon />
              <DeleteIcon />
            </Grid>
          })} */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={8}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="heading"
                label="Keywords Heading"
                placeholder="Keywords Heading"
                value={formik.values.heading}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={formik.touched.heading && Boolean(formik.errors.heading)}
                helperText={formik.touched.heading && formik.errors.heading}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              {/* <Box display="flex" justifyContent="flex-end">
         <Button>Button Aligned To The Right</Button>
         </Box> */}
              <div style={{ textAlign: 'end' }} >
                <CustomButton onClick={() => handleAdd(true)} sx={{ bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText' }}>Add New</CustomButton>
              </div>

            </Grid>
          </Grid>
          <Grid item xs={12}>
          <Box display={'flex'} flexWrap={'wrap'}>
          {searchResult?.map((item, index) => {
            return <Box key={index.toString()} sx={{}}>
              <CheckboxField
                key={index}
                labelPlacement={'end'}
                subLableText={item?.key}
                value={item?.checked}
                handleChange={(e) => { handleCheckBox(e, item?.key, index) }}
              />
              {/* <span >{img.label}</span> */}
            </Box>
          })}
          </Box>
          <div  >
          <FormHelperText style={{color:'#d32f2f'}} >{formik.touched.content && formik.errors.content}</FormHelperText>
          </div>
          </Grid>
         
        </Grid>
        <AddCMSkeyword open={addModal} handleOpen={handleAdd} handleKeyword={handleKeyword} handleLast={getListing} />
        <div
          style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
        >
          <CustomButton
            color={'buttonPrimary'}
            type="submit"
            loading={loading}
          >
            Save
          </CustomButton>
        </div>
      </Box>


    </>
  );
}

export default PopularSearch;