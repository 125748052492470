export const ModulesName = [
    {
        label: 'Select',
        value: ''
    },
    {
        label: 'Users',
        value: 'Users'
    },
    {
        label: 'Contributors',
        value: 'Contributors'
    },
    {
        label: 'Keywords',
        value: 'Keywords'
    },
    {
        label: 'Images',
        value: 'Images'
    },
    {
        label: 'Videos',
        value: 'Videos'
    },
    {
        label: 'Orders',
        value: 'Orders'
    },
    {
        label: 'Subscriptions',
        value: 'Subscriptions'
    },
    {
        label: 'Settings',
        value: 'Settings'
    },
    {
        label: 'Contact Us',
        value: 'Contact Us'
    },
    {
        label: 'Admin Users & Access',
        value: 'Admin Users & Access'
    },
    {
        label: 'Logs',
        value: 'Logs'
    },
    {
        label: 'CRM',
        value: 'CRM'
    },
    {
        label: 'Categories',
        value: 'Categories'
    },
    {
        label: 'Companies',
        value: 'Companies'
    },
    {
        label: 'Star Industries',
        value: 'Star Industries'
    },
    {
        label: 'Reports',
        value: 'Reports'
    },
    {
        label: 'CMS',
        value: 'CMS'
    },
    {
        label: 'Models',
        value: 'Models'
    }

];

export const Action = [
    {
        label: 'Select',
        value: ''
    },
    {
        label: 'Add',
        value: 'add'
    },
    {
        label: 'Delete',
        value: 'delete'
    },
    {
        label: 'Update',
        value: 'update'
    },
    {
        label: 'Export',
        value: 'export'
    },
    {
        label: 'Block',
        value: 'block'
    },
    {
        label: 'Unblock',
        value: 'unblock'
    },
    {
        label: 'Mark as Paid',
        value: 'mark_as_Paid'
    },
    {
        label: 'Mark as Unpaid',
        value: 'mark_as_unPaid'
    },
    {
        label: 'Download',
        value: 'download'
    },
    {
        label: 'Email Sent',
        value: 'mail_sent'
    },
    {
        label: 'Approve',
        value: 'image_approve'
    },
    {
        label: 'Reject',
        value: 'image_reject'
    },
    {
        label: 'Resolve',
        value: 'query_resolve'
    },
    {
        label: 'Pending',
        value: 'query_pending'
    },
    {
        label: 'Response',
        value: 'query_response'
    },
    {
        label: 'CSV Upload',
        value: 'CSV_upload'
    },
    {
        label: 'Link',
        value: 'link'
    },
    {
        label: 'Suspend',
        value: 'suspend'
    },
    {
        label: 'Make Star',
        value: 'make_star'
    },
    {
        label: 'Add Watermark',
        value: 'add_waterMark'
    },
    {
        label: 'Add Keyword',
        value: 'add_keyword'
    },
    {
        label: 'Added to Elastic',
        value: 'added_to_elastic'
    },
    {
        label: 'Follow Up',
        value: 'Follow_up'
    },
    {
        label: 'Upload File',
        value: 'Upload_file'
    },{
        label: 'Invoice Reject',
        value: 'Invoice_reject'
    },{
        label: 'IRN Generate',
        value: 'IRN_generate'
    },
    
];
