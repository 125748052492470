import * as React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Typography } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import {
  handleParse,
  processCSV,
} from '../../lib/helper';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { uploadVisibleKeywords } from '../../Services/keywordsApis';
import { modelExportList, modelUnassignedExportList } from '../../Services/modelApi';
import {
  action,
  logsMessagesList,
  modulesNames,
} from '../../data/logsMessages';
import { adminAddLogs } from '../../Services/commonService';

//create CSV file data in an array
//name	releaseid	mobile	modelid	f_desc
var csvFileData = [
  ['name', 'releaseid','modleid','total_model'],
  ["SB1794108", `"1111 , 11127"`,`"10647 , 158"`, 2]
];

export default function UploadAssignModelCsvModal({ open, handleOpen, getListing }) {
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [loading, setLoading] = React.useState(false);

  function download_csv_file() {
    //define the heading for each row of the data
    var csv = '';
    //merge the data with CSV
    csvFileData.forEach(function (row) {
      csv += row.join(',');
      csv += '\n';
    });
    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    //provide the name for the CSV file to be downloaded
    hiddenElement.download = 'Sample.csv';
    hiddenElement.click();
  }

  const checkCsvHeader = (data) => {
    let isValid = false;
    let validHeader = [
      'name',
      'releaseid',
      'modleid',
      'total_model'
    ];
    if (data?.length == validHeader?.length) {
      validHeader.every((item, i) => {
        if (item != data[i]) {
          isValid = false;
          return false;
        }
        if (validHeader.length == i + 1) {
          isValid = true;
        }
        // Make sure you return true. If you don't return a value, `every()` will stop.
        return true;
      });
    }
    return isValid;
  };

  const handleFileUpload = async (values, { resetForm }) => {
    try {
      
      let csvText = await handleParse(values.csvFile);
      let csvHeaderArray = processCSV(csvText);
      if (csvHeaderArray?.length) {
        if(checkCsvHeader(csvHeaderArray)){
          setLoading(true);
          const formData = new FormData();
          formData.append("uploaded_file", values.csvFile);
          let data = await modelUnassignedExportList(formData,  {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          if(data){
            handleOpen(false);
            resetForm({
              csvFile: '',
            });
            getListing()
            let payloadForLogs = {
              action: action?.csvUpload,
              module: modulesNames?.model,
              description: logsMessagesList?.model?.modelCSVUploadUnassign,
            };
            await adminAddLogs(payloadForLogs);
          }
        }else{
          toast.error("Maybe your CSV headers format is incorrect. Please check and try again.",{
            autoClose: 3000
          })
        }
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e, 'error');
    }
  };

  const formik = useFormik({
    initialValues: {
      csvFile: '',
    },
    validationSchema: Yup.object({
      csvFile: Yup.mixed()
        .required('CSV file is required.')
        .test(
          'fileType',
          'Unsupported File Format. It should be CSV file only.',
          (value) => ['.csv', 'text/csv', 'application/vnd.ms-excel'].includes(value?.type),
        ),
    }),
    onSubmit: handleFileUpload,
  });

  return (
    <>
      <Dialog
        maxWidth={maxWidth}
        open={open}
        onClose={() => {
          if(!loading){
            handleOpen(false);
          }
        }}
      >
        <DialogTitle>Upload CSV File</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2} mt={0}>
              <Grid item xs={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="csvFile"
                  label="CSV File"
                  placeholder="CSV File"
                  type="file"
                  // value={formik.values.order_status}
                  onChange={(e) => {
                    formik.setFieldValue('csvFile', e.target.files[0]);
                  }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.csvFile && Boolean(formik.errors.csvFile)
                  }
                  helperText={formik.touched.csvFile && formik.errors.csvFile}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{fontWeight: 'bold',fontSize: 12, textAlign: 'center', color: 'red'}}>Note*: Inserted data in CSV should be in proper format and verified else it will introduce random errors on our website.</Typography>
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                onClick={()=>{
                  download_csv_file()
                }}
                disabled={loading}
              >
                Download Sample CSV
              </CustomButton>
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Upload
              </CustomButton>
              <CustomButton
                onClick={() => handleOpen(false)}
                disabled={loading}
              >
                Close
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
//uploading
