import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import CustomPhoneInput from '../CommonComp/CustomInput/CustomPhoneInput';
import CustomCheckbox from '../CommonComp/CustomInput/CheckboxField';
import { createAdminUser } from '../../Services/adminUsersApis';
import { adminAddLogs } from '../../Services/commonService';
import { action,logsMessagesList, modulesNames, } from '../../data/logsMessages';

function CreateAdminUserModal({ open, handleOpen, getListing }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true)
    let resp = await createAdminUser(values)
    if (resp) {
      resetForm();
      getListing()
      let payloadForLogs = {
                action: action?.add,
                module: modulesNames?.adminAndAccess,
                description:logsMessagesList?.adminAndAccess?.adminAdd,
      };
      await adminAddLogs(payloadForLogs);
      handleOpen(false)
    }
    setLoading(false)
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      is_superadmin: '0',
      status: '1',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Please enter Email')
        .required('Please enter Email'),
      password: Yup.string()
        .required('Password is required.')
      ,
      first_name: Yup.string().required('First name is required.'),
      last_name: Yup.string().required('Last name is required.'),
      phone_number: Yup.string().required('Mobile Number is required')
        .max(18, "Mobile Number is Invalid")
        .min(10, "Mobile Number is Invalid"),
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'sm'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          Create Admin
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="email"
                  label="Email"
                  placeholder="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="password"
                  label="Password"
                  placeholder="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="first_name"
                  label="First Name"
                  placeholder="First Name"
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                  helperText={formik.touched.first_name && formik.errors.first_name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="last_name"
                  label="Last Name"
                  placeholder="Last Name"
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                  helperText={formik.touched.last_name && formik.errors.last_name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomPhoneInput
                  label="Mobile"
                  id="phone_number"
                  name="phone_number"
                  className="main-login-form"
                  fullWidth
                  placeholder="Mobile Number"
                  native={true}
                  value={formik.values.phone_number}
                  defaultCountry={`in`}
                  country={`in`}
                  divStyle={{
                    paddingTop: 4
                  }}
                  variant="outlined"
                  onChange={(newValue, info) => {
                    formik.setFieldValue("phone_number", newValue);
                  }}
                  onlyCountries={['in']}
                  error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                  helperText={formik.touched.phone_number && formik.errors.phone_number}
                />
              </Grid>
              <Grid item xs={5} md={3}>
                <CustomCheckbox
                  name="status"
                  label={"Status"}
                  labelPlacement={'end'}
                  subLableText={'Active'}
                  value={formik.values.status == 1 ? true : false}
                  handleChange={(e) => { formik.setFieldValue('status', e.target.checked == true ? "1" : "0") }}
                  error={
                    formik.touched.status &&
                    Boolean(formik.errors.status)
                  }
                  helperText={
                    formik.touched.status && formik.errors.status
                  }
                />
              </Grid>
              <Grid item xs={5} md={3}>
                <CustomCheckbox
                  name="is_superadmin"
                  label={"SuperAdmin"}
                  labelPlacement={'end'}
                  subLableText={'Active'}
                  value={formik.values.is_superadmin == 1 ? true : false}
                  handleChange={(e) => { formik.setFieldValue('is_superadmin', e.target.checked == true ? "1" : "0") }}
                  error={
                    formik.touched.is_superadmin &&
                    Boolean(formik.errors.is_superadmin)
                  }
                  helperText={
                    formik.touched.is_superadmin && formik.errors.is_superadmin
                  }
                />
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Submit
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    email: '',
                    password: '',
                    first_name: '',
                    last_name: '',
                  });
                  handleOpen(false)
                }
                }
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CreateAdminUserModal;
