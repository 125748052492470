import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import { createFaq, editFaq, getFAQSListing } from '../../Services/cmsService';
import TextEditor from '../CommonComp/TextEditor/TextEditor';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';
import { adminAddLogs } from '../../Services/commonService';

function AddEditFAQSModal({ open, handleOpen, data, type, getListing }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleConfirm = async (values, { resetForm }) => {
    try {
      setLoading(true)
      if (values.answer) {
        let newString = values.answer
        newString = newString.split('<p')
        if (newString?.length) {
          newString = newString.join(' <p style="margin: 0px" ')
        } else {
          newString = values.answer
        }

        newString = newString.split('<br')
        if (newString?.length) {
          newString = newString.join(` <br style="display: block; content: ''; padding: 5px;" `)
        } else {
          newString = values.answer
        }

        newString = newString.split('<a')
        if (newString?.length) {
          newString = newString.join(' <a class="table-vertical-bluetext-technical" ')
        } else {
          newString = values.answer
        }

        let payload = {
          ...values,
          answer: newString
        }
        let resp = false;
        if (type == 'add') {
          resp = await createFaq(payload);
        }
        if (type == 'edit') {
          resp = await editFaq({ ...payload, faq_id: data?.faq_id })
        }
        if (resp) {
          let payloadForLogs = {
            action: type=='edit'?action?.update:action?.add,
            module: modulesNames?.cms,
            description:type=='edit'? logsMessagesList?.cms?.faqUpdated:logsMessagesList?.cms?.faqAdded,
          };
          await adminAddLogs(payloadForLogs);
          resetForm()
          getListing();
          handleOpen(false)
        }
      }

      setLoading(false);
    } catch (e) {
      setLoading(false)
      console.log(e, 'error')
    }
  };

  const formik = useFormik({
    initialValues: {
      question: data?.question || '',
      answer: data?.answer || '',
      status: data?.status || '0',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      question: Yup.string().required('Name is required.'),
      answer: Yup.string().required('Description is required.'),
      status: Yup.string().required('Status is required.'),
    }),
    onSubmit: handleConfirm,
  });


  const handleChange = (content) => {
    if (content == '<p><br></p>') {
      formik.setFieldValue('answer', '')
    } else {
      formik.setFieldValue('answer', content)
    }
  }

  return (
    <div>
      <Dialog
        maxWidth={'sm'}
        open={open}
        fullWidth
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          {type == 'add' ? "Add FAQ" : "Edit FAQ"}
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="question"
                  label="Question"
                  placeholder="Question"
                  value={formik.values.question}
                  multiline
                  maxRows={4}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.question && Boolean(formik.errors.question)}
                  helperText={formik.touched.question && formik.errors.question}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography variant='h6' style={{ marginBottom: 3, paddingLeft: 3 }}>Answer</Typography>
                <TextEditor content={formik.values.answer} handleChange={handleChange}
                  error={formik.touched.answer && Boolean(formik.errors.answer)}
                  helperText={formik.touched.answer && formik.errors.answer}
                  modules={{
                    toolbar: [
                      ["link"],
                      [{ color: ["red", "#785412", '#000'] }],
                      [{ background: ["red", '#fff', '#0000', "#785412"] }],
                      [{ list: "ordered" }, { list: "bullet" }],
                      // [{ 'indent': '-1'}, { 'indent': '+1' }],
                      ["bold", "underline"],
                    ]
                  }}
                  label="Answer"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="status"
                  label="Status"
                  placeholder="Status"
                  value={formik.values.status}
                  select
                  SelectProps={{
                    native: true,
                  }}
                  InputLabelProps={{ shrink: true }}
                  onChange={formik.handleChange}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.status && Boolean(formik.errors.status)}
                  helperText={formik.touched.status && formik.errors.status}
                >
                  <option value={'0'}>Inactive</option>
                  <option value={'1'}>Active</option>
                </CustomInput>
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                {type == 'add' ? 'Add' : 'Update'}
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    name: '',
                    image_url: '',
                    subject: '',
                    answer: '',
                  });
                  handleOpen(false)
                }
                }
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddEditFAQSModal;
