import { Delete, Edit } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { DATA_LIMIT } from '../../data/constants';
import { formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { adminLogsSelector } from '../../redux/adminLogs';
import { deleteAdminUser, listOfAdminUserBy } from '../../Services/adminUsersApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import ConfirmationModal from '../Modals/ConfirmationModal';
import MatTable from '../Tables/MatTable';
import { AdminLogsListing,deleteAdminLog } from '../../Services/commonService';
import AllFilters from '../Order/AllFilters';
import { ModulesName, Action } from '../../data/AdminLogsData';
import DeleteLogsAdminModal from '../Modals/DeleteLogsAdminModal';

const limit = DATA_LIMIT;

const column = [
  {
    label: 'ID',
    id: 'id',
    format: (columnData) => (<Typography
      variant='span'
      style={{ whiteSpace: 'nowrap' }}  
     > {columnData}</Typography>)
  },
  {
    label: 'Module',
    id: 'module',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content"}}>{columnData || 'N/A'}</span>)
  },
  // {
  //   label: 'Admin ID',
  //   id: 'admin_id',
  //   format: (columnData) => (<Typography
  //     variant='span'
  //     style={{ whiteSpace: 'nowrap' }}  
  //    > {columnData}</Typography>)
  // },
  {
    label: 'Admin Info',
    id: 'admin_id',
    format: (columnData, rowIndex, formatCallback, option, errors, row) => (
        <>
            <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>
                <Typography variant='span' className='fontWeight600'>Email:</Typography> 
                {row?.admin?.email ? row?.admin?.email : 'N/A'}
            </span>
            <br/>
            <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>
                <Typography variant='span' className='fontWeight600'>Name:</Typography> 
                {row?.admin?.first_name ? `${row?.admin?.first_name} ${row?.admin?.last_name ? row?.admin?.last_name : ''}` : 'N/A'}
            </span>
        </>
    )
  },
  {
    label: 'Description',
    id: 'description',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 300, width: "max-content"}}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Action',
    id: 'action',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Created At',
    id: 'created_at',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap"}}>{formatDate(columnData, 'dd-mm-yyyy')}</span>)
  },
];

function AdminLogsUserList() {
  const [tableListing, setTableListing] = useState([])
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const [selectedData, setSelectedData] = useState(null);
  const { loading, allAdminLogsList } = useSelector(adminLogsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [bulkRemove,setBulkRemove]=useState(false);
  const [adminList,setAdminList]=useState([]);
   const [filtersArray, setFiltersArray] = useState([
      {
        label: 'From Date',
        id: 'start_date',
        type: 'input',
        linkedWith: null,
        value: '',
        onChange: (e, index, value) => {
          setFiltersArray((prevState) => {
            let newState = [...prevState];
            newState[index].value = e.target.value;
            return newState;
          });
        },
        style: {},
        dataArr: [],
        props: { type: 'date' },
        isVisible: true,
      },
      {
        label: 'To Date',
        id: 'end_date',
        type: 'input',
        linkedWith: null,
        value: '',
        onChange: (e, index, value) => {
          setFiltersArray((prevState) => {
            let newState = [...prevState];
            newState[index].value = e.target.value;
            return newState;
          });
        },
        style: {},
        dataArr: [],
        props: { type: 'date' },
        isVisible: true,
      },
      {
        label: 'Module',
        id: 'module',
        type: 'select',
        linkedWith: null,
        value: '',
        onChange: (e, index, value) => {
          setFiltersArray((prevState) => {
            let newState = [...prevState];
            newState[index].value = e?.target?.value;
            newState[5].value='';
            return newState;
          });
        },
        style: {},
        dataArr: ModulesName,
        props: {},
        isVisible: true,
      },
      {
        label: 'Action',
        id: 'action',
        type: 'select',
        linkedWith: null,
        value: '',
        onChange: (e, index, value) => {
          setFiltersArray((prevState) => {
            let newState = [...prevState];
            newState[index].value = e?.target?.value;
            newState[5].value='';
            return newState;
          });
        },
        style: {},
        dataArr: Action,
        props: {},
        isVisible: true,
      },
      {
        label: 'Created By',
        id: 'admin_id',
        type: 'select',
        linkedWith: null,
        value: '',
        onChange: (e, index, value) => {
          setFiltersArray((prevState) => {
            let newState = [...prevState];
            newState[index].value = e?.target?.value;
            newState[5].value='';
            return newState;
          });
        },
        style: {},
        dataArr: [
            { label: 'All', value: '' }
        ],
        props: {},
        isVisible: true,
      },
      {
        label: 'Search Text',
        id: 'search',
        type: 'input',
        linkedWith: null,
        value: '',
        onChange: (e, index, value) => {
          setFiltersArray((prevState) => {
            let newState = [...prevState];
            newState[index].value = e?.target?.value?.trim();
            return newState;
          });
        },
        style: {},
        dataArr: [],
        props: {},
        isVisible: true,
      },
    ]);

    const handleBulkRemove=(value)=>{
      setBulkRemove(value);
    }

    const getUsersList = useCallback(async (page, limit) => {
        const payload = {
            page: String(1),
            limit: String(100),
        };
        let data = await listOfAdminUserBy (payload);  
        setAdminList(data);    
        setFiltersArray((prevState) => {
            let newState = [...prevState];
            newState[4].dataArr = [{ label: 'All', value: '' },
            ...data?.map((el) => {
                return { 
                    label: `${el?.first_name} ${el?.last_name?el?.last_name:""}`,
                    value: el?.admin_id };
            })];
            return newState;
        });
    }, []);
  

  const getListing = async (params={}) => {
    const payload = {
        page: String(1), 
        limit: limit,
        ...allParams,
        ...params
    }
    if (payload?.start_date && payload?.end_date) {
          if (payload?.start_date) {
            payload['start_date'] =
              formatDate(payload?.start_date, 'yyyy-mm-dd') + '  00:00:00';
          }
          if (payload?.end_date) {
            payload['end_date'] =
              formatDate(payload?.end_date, 'yyyy-mm-dd') + '  23:59:59';
          }
        }
    dispatch(AdminLogsListing(payload))
    setPage(Number(payload.page))
  };

  const handleFilter = (data, allDatavalues, dataWithValue) => {
    getListing(
      {
        page: String(1),
        ...dataWithValue,
      },
      true,
    );
    setAllParams({
      page: String(1),
      ...dataWithValue,
    });
  };

  const handleClear = () => {
      const data = getObjectSubset({ ...allParams }, 'tab', 'page');
      setAllParams(data);
      setFiltersArray((prevState) => {
        let newState = [...prevState];
        newState[0].isVisible = true;
        newState[1].isVisible = true;
        newState[2].isVisible = true;
        newState[3].isVisible = true;
        newState[4].isVisible = true;
        newState[5].isVisible = true;

        newState[0].value = '';
        newState[1].value = '';
        newState[2].value = '';
        newState[3].value = '';
        newState[4].value = '';
        newState[5].value = '';
        return newState;
      });
    };

  useEffect(() => {
    getListing();
    getUsersList();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(()=>{
    let data = getNewParameters(allParams)
    if(data?.search !== location.search){
      navigate(data.newUrl, {replace: false})
      if(data?.parameters?.page){
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  },[allParams])

  useEffect(() => {
    if(allAdminLogsList?.items?.rows?.length >= 0){
      setTableListing(allAdminLogsList?.items?.rows);
    }
  }, [allAdminLogsList]);


  const actionBody = (data) => {
    return (
      <>
        <div style={{display: "flex"}}>
           <CustomButton className="noMinWidth buttonDense" toolTipTittle={'Delete Admin'}  
           onClick={()=>{
           handleCofirmModalOpen(true)
            setSelectedData(data)
         }} 
           sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}><Delete/></CustomButton>
        </div>
      </>
    );
  };

  
  const handleCofirmModalOpen = (value) => {
    setConfirmDelete(value);
  };

  const handleBulkRemoveCofirmModalOpen = (value) => {
    setBulkRemove(value);
  };

  const handleConfirmDelete = async (value) => {
    if (value && selectedData) {
      const resp = await deleteAdminLog({ id: `${selectedData?.id}` });
      if (resp) {
        getListing();
        setSelectedData(null);
      }
    } else {
      setSelectedData(null);
    }
  };
  return (
    <>
      <MatTable
        loading={allAdminLogsList?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data={})=>{
          getListing({
            page: String(1), 
            start_date: "",
            end_date: "",
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if(Object.keys(data).length > 0){
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          }else{
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        page={page}
        perPage={limit}
        total={allAdminLogsList?.items?.count || 0}
        setPage={setPage}
        handleSearch={(searchTerm)=>{
          getListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage)=>{
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        disableSearch={true}
        customFilter={() => (
            <AllFilters
              filtersArray={filtersArray}
              handleClick={handleFilter}
              handleClear={handleClear}
              extraButton={() => {
                            return (
                              <>
                              {allAdminLogsList?.items?.count>0 &&
                                <CustomButton
                                  onClick={() => {
                                    handleBulkRemove(true);
                                  }}
                                  color={'buttonDanger'}
                                  sx={(theme) => ({
                                    padding: '10px 10px',
                                    marginRight: 1,
                                    marginTop: 1,
                                    [theme.breakpoints.down('md')]: {
                                      flex: 1,
                                    },
                                  })}
                                >
                                  Bulk Delete
                                </CustomButton>
                               }
                              </>
                            );
                          }}
            />
          )}
      />

      
      {confirmDelete && (
        <ConfirmationModal
          open={confirmDelete}
          handleConfirm={handleConfirmDelete}
          setOpen={handleCofirmModalOpen}
        />
      )}

{
        bulkRemove &&<DeleteLogsAdminModal 
                      open={bulkRemove}
                      handleOpen={handleBulkRemove}
                      // setOpen={handleBulkRemoveCofirmModalOpen}
                      adminList={adminList}
                      getListing={getListing}
        
        />
      }
    </>
  );
}

export default AdminLogsUserList;