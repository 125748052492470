import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import CustomAsyncSelect from '../CommonComp/CustomInput/CustomAsyncSelect';
import { deleteAdminUsersLogs } from '../../Services/adminLogs';

function DeleteLogsAdminModal({ open, handleOpen, getListing,adminList }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true);
    let admin_id=values?.admins?.map((id)=>id?.value)?.join(",");
    const payload={admin_id:admin_id};
    let resp = await deleteAdminUsersLogs(payload);
    if (resp) {
      resetForm();
      getListing()
      // let payloadForLogs = {
      //           action: action?.add,
      //           module: modulesNames?.adminAndAccess,
      //           description:logsMessagesList?.adminAndAccess?.adminAdd,
      // };
      // await adminAddLogs(payloadForLogs);
      handleOpen(false)
    }
    setLoading(false)
  };

  const getAdminListing = async (inputValue) => {
    if (adminList) {
      let updatedData = adminList?.map((admin, index) => {
        return {
          value: admin?.admin_id,
          label: `${admin?.first_name} ${admin?.last_name?admin?.last_name:''}`,
        };
      });
      return updatedData || [];
    }
  };

  const formik = useFormik({
      initialValues: {
        admins: [],
      },
      enableReinitialize: true,
      validationSchema: Yup.object({
        admins: Yup.array().min(1, 'At least one admin is required'),
      }),
      onSubmit: handleConfirm,
    });
    

  return (
     <div>
       <Dialog
         maxWidth={'sm'}
         open={open}
         onClose={() => {
           handleOpen(false);
         }}
         PaperProps={{
           style: { width: '600px', maxWidth: '100%' }, // Set your desired width here
         }}
       >
         <DialogTitle variant="h3" padding={3} margin={2}>
           Remove All logs for Single/Multiple Admins
         </DialogTitle>
         <DialogContent>
           <Box noValidate component="form" onSubmit={formik.handleSubmit}>
             <Grid container spacing={2}>
               <Grid item xs={12}>
                 <CustomAsyncSelect
                   promiseOptions={getAdminListing}
                   closeMenuOnSelect={false}
                   controlStyle={{
                     padding: 5,
                     minHeight: '56px',
                   }}
                   cacheOptions={true}
                   value={formik.values.admins}
                   error={
                     formik.touched.admins &&
                     Boolean(formik.errors.admins)
                   }
                   helperText={
                     formik.touched.admins &&
                     formik.errors.admins
                   }
                   handleChange={(e) => {
                    //  setSelectedState(e);
                     formik.setFieldValue('admins', e);
                   }}
                   width={'100%'}
                   dropDownZIndex={100000}
                   labelShrink={true}
                   label="admin"
                 />
               </Grid>
             </Grid>
             <div
               style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
             >
               <CustomButton
                 color={'buttonPrimary'}
                 type="submit"
                 loading={loading}
               >
                 Remove Logs
               </CustomButton>
               <CustomButton
                 onClick={() => {
                   formik.resetForm({
                     email: '',
                     password: '',
                     fName: '',
                     lName: '',
                   });
                   handleOpen(false);
                 }}
                 disabled={loading}
               >
                 Cancel
               </CustomButton>
             </div>
           </Box>
         </DialogContent>
       </Dialog>
     </div>
   );
}

export default DeleteLogsAdminModal;
