import { Delete, Edit } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DATA_LIMIT } from '../../data/constants';
import {
  formatDate,
  getNewParameters,
  getObjectSubset,
  getParameters,
} from '../../lib/helper';
import { adminUsersItemsSelector } from '../../redux/adminUsers';
import {
  adminUsersListing,
  deleteAdminUser,
} from '../../Services/adminUsersApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import ConfirmationModal from '../Modals/ConfirmationModal';
import CreateAdminUserModal from '../Modals/CreateAdminUserModal';
import EditAdminUserModal from '../Modals/EditAdminUserModal';
import EditPasswordAdminUserModal from '../Modals/EditPasswordAdminUserModal';
import MatTable from '../Tables/MatTable';
import LockResetIcon from '@mui/icons-material/LockReset';
import { ScreenLockLandscape } from '@mui/icons-material';
import AccessModal from '../Modals/AccessModal';
import {
  action,
  logsMessagesList,
  modulesNames,
} from '../../data/logsMessages';
import { adminAddLogs } from '../../Services/commonService';
import AllFilters from '../Order/AllFilters';
const limit = DATA_LIMIT;

const column = [
  {
    label: 'ID',
    id: 'admin_id',
    format: (columnData) => (
      <Typography variant="span" style={{ whiteSpace: 'nowrap' }}>
        {' '}
        {columnData}
      </Typography>
    ),
  },
  {
    label: 'Email',
    id: 'email',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'First Name',
    id: 'first_name',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 200, width: 'max-content' }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Last Name',
    id: 'last_name',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}
      >
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Mobile',
    id: 'phone_number',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData || 'N/A'}
      </span>
    ),
  },
  {
    label: 'Department',
    id: 'department',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', maxWidth: 300, width: 'max-content' }}
      >
        {columnData || '-'}
      </span>
    ),
  },
  {
    label: 'Status',
    id: 'status',
    format: (columnData) => (
      <Typography
        variant="span"
        sx={{
          ...(columnData == 1
            ? { color: 'success.main' }
            : columnData == 2
            ? { color: 'danger.main' }
            : columnData == 0
            ? { color: 'info.main' }
            : {}),
          whiteSpace: 'nowrap',
          display: 'inline-block',
        }}
      >
        {columnData == 0
          ? 'Inactive'
          : columnData == 1
          ? 'Active'
          : columnData == 2
          ? 'Deleted'
          : 'N/A'}
      </Typography>
    ),
  },
  {
    label: 'Created At',
    id: 'created_at',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {formatDate(columnData, 'dd-mm-yyyy')}
      </span>
    ),
  },
];

function AllAdminUserList() {
  const [tableListing, setTableListing] = useState([]);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const [addAdminModal, setAddAdminModal] = useState(false);
  const [editAdminModal, setEditAdminModal] = useState(false);
  const [editPasswordAdminModal, setEditPasswordAdminModal] = useState(false);
  const [PermissionModel, setPermissionModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const { loading, allAdminUsersList } = useSelector(adminUsersItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [confirmDelete, setConfirmDelete] = useState(false);

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      // user_type: "2",
      ...allParams,
      ...params,
    };
    dispatch(adminUsersListing(payload));
    setPage(Number(payload.page));
  };

  const [filtersArray, setFiltersArray] = useState([
    {
      label: 'From Date',
      id: 'start_date',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
    {
      label: 'To Date',
      id: 'end_date',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
    {
      label: 'Search Text',
      id: 'search',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e?.target?.value?.trim();
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: {},
      isVisible: true,
    },
  ]);

  const handleFilter = (data, allDatavalues, dataWithValue) => {
    getListing(
      {
        page: String(1),
        ...dataWithValue,
      },
      true,
    );
    setAllParams({
      page: String(1),
      ...dataWithValue,
    });
  };

  const handleClear = () => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0].isVisible = true;
      newState[0].value = '';
      newState[1].isVisible = true;
      newState[1].value = '';
      newState[2].isVisible = true;
      newState[2].value = '';

      return newState;
    });
  };

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters();
    setAllParams(params);
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams);
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false });
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page));
      }
    }
    getListing();
  }, [allParams]);

  //making data format for table input
  useEffect(() => {
    if (allAdminUsersList?.items?.data?.length >= 0) {
      setTableListing(allAdminUsersList?.items?.data);
    }
  }, [allAdminUsersList.items]);

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <CustomButton
            className="noMinWidth buttonDense"
            toolTipTittle={'Update Password'}
            onClick={() => {
              handleEditPasswordAdminModal(true);
              setSelectedData(data);
            }}
            sx={{
              padding: '5px 10px',
              marginRight: 1,
              fontSize: '12px',
              bgcolor: 'buttonSecondary.main',
              color: 'buttonSecondary.contrastText',
            }}
          >
            <LockResetIcon />
          </CustomButton>

          <CustomButton
            className="noMinWidth buttonDense"
            toolTipTittle={'Screen Access'}
            onClick={() => {
              handleAccessModal(true);
              setSelectedData(data);
            }}
            sx={{
              padding: '5px 10px',
              marginRight: 1,
              fontSize: '12px',
              bgcolor: 'buttonSecondary.main',
              color: 'buttonSecondary.contrastText',
            }}
          >
            <ScreenLockLandscape />
          </CustomButton>

          <CustomButton
            className="noMinWidth buttonDense"
            toolTipTittle={'Edit Admin'}
            onClick={() => {
              setSelectedData(data);
              handleEditAdminModal(true);
            }}
            sx={{
              padding: '5px 10px',
              marginRight: 1,
              fontSize: '12px',
              bgcolor: 'buttonPrimary.main',
              color: 'buttonPrimary.contrastText',
            }}
          >
            <Edit />
          </CustomButton>
          <CustomButton
            className="noMinWidth buttonDense"
            toolTipTittle={'Delete Admin'}
            onClick={() => {
              handleCofirmModalOpen(true);
              setSelectedData(data);
            }}
            sx={{
              padding: '5px 10px',
              marginRight: 1,
              fontSize: '12px',
              bgcolor: 'buttonSecondary.main',
              color: 'buttonSecondary.contrastText',
            }}
          >
            <Delete />
          </CustomButton>
        </div>
      </>
    );
  };

  // const actionHead = (data) => {
  //   return (<div style={{display: "flex"}}>
  //      hi
  //   </div>);
  // };

  const handleAddAdminModal = (value = true) => {
    setAddAdminModal(value);
  };
  const handleEditAdminModal = (value = true) => {
    setEditAdminModal(value);
    if (!value) {
      setSelectedData(null);
    }
  };

  const handleEditPasswordAdminModal = (value = true) => {
    setEditPasswordAdminModal(value);
    if (!value) {
      setSelectedData(null);
    }
  };

  const handleAccessModal = (value = true) => {
    setPermissionModal(value);
    if (!value) {
      setSelectedData(null);
    }
  };

  const handleCofirmModalOpen = (value) => {
    setConfirmDelete(value);
  };
  const handleConfirmDelete = async (value) => {
    if (value && selectedData) {
      const resp = await deleteAdminUser({ admin_id: selectedData.admin_id });
      if (resp) {
        let payloadForLogs = {
          action: action?.delete,
          module: modulesNames?.adminAndAccess,
          description: logsMessagesList?.adminAndAccess?.adminDelete,
        };
        await adminAddLogs(payloadForLogs);
        getListing();
        setSelectedData(null);
      }
    } else {
      setSelectedData(null);
    }
  };
  return (
    <>
      <MatTable
        loading={allAdminUsersList?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        // requestSearch={requestSearch}
        // cancelSearch={cancelSearch}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        // showFilterButton={true}
        // showSerialNo={false}
        action={actionBody}
        // actionHead={actionHead}
        page={page}
        // changeFilter={changeFilter}
        // handleFilterPopup={toggleFilters}
        perPage={limit}
        // setFilters={setFilters}
        // filters={filters}
        total={allAdminUsersList?.items?.totalCount || 0}
        // defaultSort="transactionTime"
        // defaultSortOrder={isMobile ? sortOrder : 'desc'}
        // searchTerm={searchTerm}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        // filterEnable
        // rangeDateFilter
        customFilter={() => (
          <AllFilters
            filtersArray={filtersArray}
            handleClick={handleFilter}
            handleClear={handleClear}
            extraButton={() => {
              return (
                <>
                  <CustomButton
                    onClick={() => {
                      handleAddAdminModal(true);
                    }}
                    color={'buttonPrimary'}
                    sx={(theme) => ({
                      padding: '10px 10px',
                      marginRight: 1,
                      marginTop: 1,
                      [theme.breakpoints.down('md')]: {
                        flex: 1,
                      },
                    })}
                  >
                    Add Admin
                  </CustomButton>
                </>
              );
            }}
          />
        )}
        disableSearch
      />

      {addAdminModal && (
        <CreateAdminUserModal
          open={addAdminModal}
          handleOpen={handleAddAdminModal}
          getListing={getListing}
        />
      )}
      {editAdminModal && (
        <EditAdminUserModal
          open={editAdminModal}
          handleOpen={handleEditAdminModal}
          getListing={getListing}
          selectedData={selectedData}
        />
      )}
      {editPasswordAdminModal && (
        <EditPasswordAdminUserModal
          open={editPasswordAdminModal}
          handleOpen={handleEditPasswordAdminModal}
          getListing={getListing}
          selectedData={selectedData}
        />
      )}
      {PermissionModel && (
        <AccessModal
          open={PermissionModel}
          handleOpen={handleAccessModal}
          getListing={getListing}
          selectedData={selectedData}
        />
      )}

      {confirmDelete && (
        <ConfirmationModal
          open={confirmDelete}
          handleConfirm={handleConfirmDelete}
          setOpen={handleCofirmModalOpen}
        />
      )}

     
    </>
  );
}

export default AllAdminUserList;
