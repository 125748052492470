import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { breakStringIntoLines, formatDate, getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import { starIndustriesItemsSelector } from '../../redux/starIndustries';
import { deleteIndusry, starIndustriesListings } from '../../Services/starIndustriesApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import MatTable from '../Tables/MatTable';
import AddStarIndustry from '../Modals/AddStarIndustry';
import { Delete, Edit } from '@material-ui/icons';
import ConfirmationModal from '../Modals/ConfirmationModal';
import AllFilters from '../Order/AllFilters';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';
import { adminAddLogs } from '../../Services/commonService';

const limit = 10;

const column = [
  {
    label: 'ID',
    id: 'star_industry_id',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData || 'N/A'}</span>
    )
  },
  {
    label: 'Group ID',
    id: 'group_id',
    format: (columnData) => (<span style={{ display: 'inline-block', whiteSpace: "nowrap" }}>{columnData || 'N/A'}</span>)
  },
  {
    label: 'Category',
    id: 'category',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 300, width: "max-content", wordBreak: 'break-all' }}>
      {columnData ? breakStringIntoLines(columnData, 20) : 'N/A'}
    </span>)
  },
  {
    label: 'Sub Category',
    id: 'sub_category',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 500, width: "max-content" }}>
      {columnData ? breakStringIntoLines(columnData, 20) : 'N/A'}
    </span>)
  },
  {
    label: 'Date',
    id: 'created_at',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content" }}>{formatDate(columnData)}</span>)
  }
];

function StarIndustriesList() {
  const [tableListing, setTableListing] = useState([])
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, starIndustriesList } = useSelector(starIndustriesItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const [addModel, setAddModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [selectedData, setSelectedData] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [starIndustryDeleteData, setStarIndustryDeleteData] = useState();
  const [filtersArray, setFiltersArray] = useState([

    {
      label: 'From Date',
      id: 'start_date',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          newState[1].value = e.target.value;
          return newState;
        });
       },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
    {
      label: 'To Date',
      id: 'end_date',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
    {
      label: 'Search Text',
      id: 'search',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e?.target?.value?.trim();
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: {},
      isVisible: true,
    },
  ]);

  const handleFilter = (data, allDatavalues, dataWithValue) => {
    getListing(
      {
        page: String(1),
        ...dataWithValue,
      },
      true,
    );
    setAllParams({
      page: String(1),
      ...dataWithValue,
    });
  };

  const handleClear = () => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0].isVisible = true;
      newState[1].isVisible = true;
      newState[2].isVisible = true;
      newState[0].value = '';
      newState[1].value = '';
      newState[2].value = '';
      return newState;
    });
  };

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      order_status: String(1),
      ...allParams,
      ...params
    }
    if (payload?.start_date && payload?.end_date) {
      if (payload?.start_date) {
        payload['start_date'] =
          formatDate(payload?.start_date, 'yyyy-mm-dd') + '  00:00:00';
      }
      if (payload?.end_date) {
        payload['end_date'] =
          formatDate(payload?.end_date, 'yyyy-mm-dd') + '  23:59:59';
      }
    }
    dispatch(starIndustriesListings(payload))
    setPage(Number(payload.page))
  }

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params)
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  }, [allParams])

  //making data format for table input
  useEffect(() => {
    if (starIndustriesList?.items?.data?.length >= 0) {
      setTableListing(starIndustriesList?.items?.data)
    }
  }, [starIndustriesList.items]);

  const handleAdd = (value = false) => {
    setAddModel(value);
  }

  const handleEdit = (value = false) => {
    setEditModel(value)
  }

  const handleConfirmDeleteIndustry = async (value) => {
    if (value && starIndustryDeleteData) {
      const resp = await deleteIndusry({ star_industry_id: starIndustryDeleteData });
      if (resp) {
        let payloadForLogs = {
          action: action?.delete,
          module: modulesNames?.star_indutries,
          description: logsMessagesList?.startIndustery?.starIndusteryDelete,
        };
        await adminAddLogs(payloadForLogs);
        getListing({ page: '1' });
        setPage(1);
      }
      setConfirmDelete(false);
    } else {
      setSelectedData(null)
    }
  }

  const handleCofirmModalOpen = (value) => {
    setSelectedData(value);
  }

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: "flex" }}>
          <CustomButton className="noMinWidth buttonDense" onClick={() => {
            setSelectedData(data)
            handleEdit(true)
          }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText' }}><Edit /></CustomButton>
          <CustomButton className="noMinWidth buttonDense" onClick={() => {
            handleCofirmModalOpen(true)
            setConfirmDelete(true);
            setStarIndustryDeleteData(data?.star_industry_id)
          }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}><Delete /></CustomButton>
        </div>
      </>
    );
  };

 const handleAddNewModal=()=>{
  setAddModel(true)
 }

  return (
    <>
      <MatTable
        loading={starIndustriesList?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: "",
            end_date: "",
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        page={page}
        perPage={limit}
        total={starIndustriesList?.items?.totalCount || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        // toolBarButton={() => {
        //   return <>
        //     <CustomButton onClick={() => { handleAdd(true) }} color={'buttonPrimary'} sx={(theme) => ({
        //       padding: '10px 10px', marginRight: 1,
        //       [theme.breakpoints.down("md")]: {
        //         flex: 1,
        //       }
        //     })}>Add Star</CustomButton>
        //   </>
        // }}
        toolbarEnable={true}
        filterEnable={false}
        pagination={true}
        filtersUrlData={allParams || {}}
        rangeDateFilter
        disableSearch={true}
        customFilter={() => (
          <AllFilters
            filtersArray={filtersArray}
            handleClick={handleFilter}
            handleClear={handleClear}
            hasAddNew={true}
            addText={"Add Industery"}
            handleAddNewModal={handleAddNewModal}
          />
        )}
      />
      {addModel && <AddStarIndustry open={addModel} handleOpen={handleAdd} setPage={setPage} handleLast={getListing} type={'add'} />}
      {editModel && <AddStarIndustry open={editModel} handleOpen={handleEdit} setPage={setPage} handleLast={getListing} type={'edit'} data={selectedData} />}
      {confirmDelete && (
        <ConfirmationModal
          open={confirmDelete}
          handleConfirm={handleConfirmDeleteIndustry}
          setOpen={handleCofirmModalOpen}
        />
      )}
    </>
  );
}

export default StarIndustriesList;
