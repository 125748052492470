const apiUrl = process.env.REACT_APP_API_URL;
const baseUrl = apiUrl;

const BASE_URL_MAIN = `${baseUrl}/`;
const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_IMAGE_URL = process.env.REACT_APP_BASE_IMAGE_URL;
const S3_BASE_URL = process.env.REACT_APP_S3_URL;
const LIVE_WEBSITE_URL = process.env.REACT_APP_LIVE_WEBSITE_URL;
const REACT_APP_SALT = process.env.REACT_APP_SALT;

let ApiConfig = {
  token: null,
  BASE_URL,
  BASE_IMAGE_URL,
  S3_BASE_URL,
  REACT_APP_SALT,
  LIVE_WEBSITE_URL,

  //common apis
  appSetting: `${BASE_URL_MAIN}common/appSetting`,
  updateSetting: `${BASE_URL_MAIN}admin/updateSetting`,
  addSetting: `${BASE_URL_MAIN}admin/addSetting`,
  fileUpload: `${BASE_URL_MAIN}admin/fileUpload`,
  getJobDescription: `${BASE_URL_MAIN}common/listAllJobs`,
  getCountry: `${BASE_URL_MAIN}common/listAllCountry`,
  getState: `${BASE_URL_MAIN}common/listAllState`,
  imageInfo: `${BASE_URL_MAIN}common/imageInfo`,
  addJob: `${BASE_URL_MAIN}common/addJob`,
  updateJobDesc: `${BASE_URL_MAIN}common/UpdateJob`,
  addCountry: `${BASE_URL_MAIN}common/addCountry`,
  updateCountry: `${BASE_URL_MAIN}common/updateCountry`,
  addState: `${BASE_URL_MAIN}common/addState`,
  updateState: `${BASE_URL_MAIN}common/updateState`,
  companyName: `${BASE_URL_MAIN}common/companyName`,
  proposalDetails: `${BASE_URL_MAIN}common/ProposalDetails`,
  imagesByName: `${BASE_URL_MAIN}shoot/imagesByName`,
  uploadImage: `${BASE_URL_MAIN}admin/uploadimage`,
  videoKeyword: `${BASE_URL_MAIN}common/videoKeywords`,
  downloadImages: `${BASE_URL_MAIN}common/downloadImages`,
  backgroundImage: `${BASE_URL_MAIN}admin/BackgroundImage`,
  bannersImages: `${BASE_URL_MAIN}common/homepagebannerimagesList`,
  deleteBannerImage: `${BASE_URL_MAIN}admin/deleteBannerImages`,

  //Admin logs api
  adminLogs : `${BASE_URL_MAIN}admin/admin_logs`,
  ListOfAdminLogs: `${BASE_URL_MAIN}admin/listOfAdminLogs`,
  deleteAdminLogById: `${BASE_URL_MAIN}admin/deleteAdminLogById`,

  //login api
  login: `${BASE_URL_MAIN}admin/login`,
  userRegister: `${BASE_URL_MAIN}admin/userRegister`,
  emailForForgotPWD: `${BASE_URL_MAIN}admin/forgetPassword`,
  forgotPwd: `${BASE_URL_MAIN}admin/recoverPassword`,


  //order api
  orderListing: `${BASE_URL_MAIN}admin/orderListing`,
  confirmOrderListing: `${BASE_URL_MAIN}admin/confirmorderList`,
  pendingOrderListing: `${BASE_URL_MAIN}admin/pendingOrderListing`,
  orderDetails: `${BASE_URL_MAIN}admin/orderDetails`,
  proposalListing: `${BASE_URL_MAIN}admin/ProposalListing`,
  ivsDownloadImages: `${BASE_URL_MAIN}admin/ivsDownloadImages`,
  editIvsDownload: `${BASE_URL_MAIN}admin/editivsdownload`,
  ivsOrders: `${BASE_URL_MAIN}admin/ivsOrders`,
  expiryMail: `${BASE_URL_MAIN}admin/expiryMail`,
  confirmOrder: `${BASE_URL_MAIN}admin/confirmOrder`,
  deleteOrder: `${BASE_URL_MAIN}admin/deleteorder`,
  orderPaid: `${BASE_URL_MAIN}/admin/paid`,
  uploadPo: `${BASE_URL_MAIN}/admin/UploadPo`,
  downloadPo: `${BASE_URL_MAIN}admin/downloadPo`,
  promotionalMail: `${BASE_URL_MAIN}admin/promotionMail`,
  markConfirmUnpaid:`${BASE_URL_MAIN}admin/markasunpaid`,


  //invoice api
  invoiceDetails: `${BASE_URL_MAIN}admin/InvoiceDetails`,
  editInvoice: `${BASE_URL_MAIN}admin/editInvoice`,
  editInvoiceItem: `${BASE_URL_MAIN}order/changeItem`,
  invoiceReject: `${BASE_URL_MAIN}admin/invoiceReject`,

  //subscription api
  orderSubscriptionListing: `${BASE_URL_MAIN}admin/subscriptionPlans`,
  selectSubscriptionListing: `${BASE_URL_MAIN}admin/selectSubscription`,
  createsubscriptionplan: `${BASE_URL_MAIN}admin/createsubscriptionplan`,
  editsubscriptionplan: `${BASE_URL_MAIN}admin/editsubscriptionplan`,

  //contributor api
  contributorListing: `${BASE_URL_MAIN}admin/listOfContributors`,
  contributorsoldimages: `${BASE_URL_MAIN}admin/contributorsoldimages`,
  contributoruploadedimages: `${BASE_URL_MAIN}admin/contributorUploadedImages`,
  contributorFolderList: `${BASE_URL_MAIN}admin/fileFolderList`,
  contributorListuploadedimages: `${BASE_URL_MAIN}admin/uploadImageContributorList`,
  createContributor: `${BASE_URL_MAIN}admin/createContributor`,
  editContributor: `${BASE_URL_MAIN}admin/editContributor`,
  deleteContributor: `${BASE_URL_MAIN}admin/deleteContributor`,
  imageStatusUpdate: `${BASE_URL_MAIN}admin/imageStatusUpdate`,
  folderLimitUpdate: `${BASE_URL_MAIN}admin/fileFolderlimit`,
  contributorBasicInfoAndBankData: `${BASE_URL_MAIN}admin/contributordetailsAndBank`,
  contributorPaymentHistory: `${BASE_URL_MAIN}admin/contributorPaymentHistoryDetails`,
  editContrubutorHistory: `${BASE_URL_MAIN}admin/editContributorPayment`,
  contributorBankDataAdd: `${BASE_URL_MAIN}admin/createcontributorBankDetails`,
  paymentMade: `${BASE_URL_MAIN}admin/addContributorPayment`,
  paymentMadeCreate: `${BASE_URL_MAIN}contributor/createPayment`,
  updatePayment: `${BASE_URL_MAIN}contributor/updatePayment`,
  listOfPayments: `${BASE_URL_MAIN}contributor/listPayment`,
  paymentDivision: `${BASE_URL_MAIN}admin/contributorimages`,
  markSoldAsPaid: `${BASE_URL_MAIN}contributor/markAsPaid`,
  blockContributor: `${BASE_URL_MAIN}admin/blockcontributor`,
  contributorDailySalesReport: `${BASE_URL_MAIN}contributor/dailySalesReport`,
  contributorPaymentListing: `${BASE_URL_MAIN}admin/getcontributorOrderDetails`,
  downloadFolderImages: `${BASE_URL_MAIN}admin/downloadfolderimages`,
  contributorQueryList: `${BASE_URL_MAIN}admin/listOfContributorQueries`,
  updateQueriesStatus:`${BASE_URL_MAIN}admin/editContributorquery`,
  sendQueryByAdmin:`${BASE_URL_MAIN}admin/sendMessageByadmin`,
  adminContributorQuery:`${BASE_URL_MAIN}admin/listofQueryMessages`,
  sendImagesMailtocontributors:`${BASE_URL_MAIN}admin/sendImagesMailtocontributor`,
  nocList:`${BASE_URL_MAIN}admin/listOfNocs`,
  deleteNoc:`${BASE_URL_MAIN}admin/deleteNocById`,
  deleteQuery:`${BASE_URL_MAIN}admin/deleteQueries`,

  //company api
  companyListing: `${BASE_URL_MAIN}admin/listOfCompany`,
  companyGroupsListing: `${BASE_URL_MAIN}admin/companyGroups`,
  companyMasterListing: `${BASE_URL_MAIN}admin/companyMaster`,
  company: `${BASE_URL_MAIN}admin/company`,
  companygroup: `${BASE_URL_MAIN}admin/companygroup`,
  addCompanyMaster: `${BASE_URL_MAIN}admin/addCompanyMaster`,
  editCompanyMaster: `${BASE_URL_MAIN}admin/updateCompany`,
  addCompanyGroups: `${BASE_URL_MAIN}admin/addCompanyGroups`,
  companyGroupByName: `${BASE_URL_MAIN}admin/companyGroupByName`,
  updateShortCompany: `${BASE_URL_MAIN}admin/updateShortCompany`,
  updateNonRegisteredCompany: `${BASE_URL_MAIN}admin/UpdateNonRegisteredCompany`,
  overviewUserLists: `${BASE_URL_MAIN}admin/OverviewUsersList`,
  overviewOrderLists: `${BASE_URL_MAIN}admin/OverviewOrdersList`,
  orderByuser:`${BASE_URL_MAIN}admin/orderByUser`,
  totalEarning:`${BASE_URL_MAIN}admin/TotalEarningCompany`,


  //category api
  categoryListing: `${BASE_URL_MAIN}admin/listOfCategories`,
  subCategoryListing: `${BASE_URL_MAIN}admin/listOfSubCategories`,
  starcategoryListing: `${BASE_URL_MAIN}admin/listOfStarCategories`,
  createCategory: `${BASE_URL_MAIN}admin/createcategory`,
  editCategory: `${BASE_URL_MAIN}admin/editcategory`,
  deleteCategory: `${BASE_URL_MAIN}admin/deleteCategory`,
  createSubCategory: `${BASE_URL_MAIN}admin/createsubcategory`,
  editSubCategory: `${BASE_URL_MAIN}admin/editsubcategory`,
  deleteSubCategory: `${BASE_URL_MAIN}admin/deletesubcategory`,
  listOfVideoCategories: `${BASE_URL_MAIN}admin/listOfVideoCategories`,
  createVideoCategory: `${BASE_URL_MAIN}admin/createVideoCategory`,
  editVideoCategory: `${BASE_URL_MAIN}admin/editVideoCategory`,
  deleteVideoCategory: `${BASE_URL_MAIN}admin/deleteVideoCategory`,
  createStarCategory: `${BASE_URL_MAIN}admin/createStarCategory`,
  deleteStarCategory: `${BASE_URL_MAIN}admin/deleteStarCategory`,
  editStarCategory: `${BASE_URL_MAIN}admin/editStarCategory`,

  //starIndustries api
  starIndustriesListing: `${BASE_URL_MAIN}admin/listOfstarIndustries`,
  createStarIndustry: `${BASE_URL_MAIN}admin/createStarIndustry`,
  editStarIndustry: `${BASE_URL_MAIN}admin/editStarIndustry`,
  deleteStarIndustry: `${BASE_URL_MAIN}admin/deleteStarIndustry`,

  //user api
  userListing: `${BASE_URL_MAIN}admin/listOfUsers`,
  adminUsersList: `${BASE_URL_MAIN}admin/adminuserslist`,
  userWishList: `${BASE_URL_MAIN}admin/wishList`,
  userCart: `${BASE_URL_MAIN}admin/cart`,
  singleUserCart: `${BASE_URL_MAIN}admin/userCartList`,
  updateUser: `${BASE_URL_MAIN}admin/updateUser`,
  addToCartAdmin: `${BASE_URL_MAIN}admin/addToCartAdmin`,
  addToWishListAdmin: `${BASE_URL_MAIN}admin/addToWishListAdmin`,
  listAdminOrders: `${BASE_URL_MAIN}admin/listAdminOrders`,
  userDatalist: `${BASE_URL_MAIN}admin/userDatalist`,
  subscribedOrdersAdmin: `${BASE_URL_MAIN}admin/subscribedOrdersadmin`,
  subscribedActiveOrdersAdmin: `${BASE_URL_MAIN}admin/ivsOrderbyId`,
  downloadImageAdmin: `${BASE_URL_MAIN}admin/downloadImageadmin`,
  deleteUser: `${BASE_URL_MAIN}admin/deleteUser`,
  updatewaterMark: `${BASE_URL_MAIN}admin/updatewaterMark`,
  upgradeImage: `${BASE_URL_MAIN}admin/upgradeToCart`,
  orderTypeById: `${BASE_URL_MAIN}admin/upgradeImagePrice`,
  isPrivillegeUser: `${BASE_URL_MAIN}admin/privilageUserCheck`,
  privilegeUserImage: `${BASE_URL_MAIN}admin/privilageClientlistforUser`,
  ibdownloadimage: `${BASE_URL_MAIN}admin/ibdownloadimage`,

  //create proposal api
  createProposal: `${BASE_URL_MAIN}admin/createProposal`,
  deleteProposal: `${BASE_URL_MAIN}admin/deleteProposal`,
  insertToCartProposal: `${BASE_URL_MAIN}admin/insertToCart`,

  //create proposal api
  createOrder: `${BASE_URL_MAIN}admin/createUserOrder`,
  createUserProposalOrder: `${BASE_URL_MAIN}admin/createUserProposalOrder`,

  //image listing api
  imageListing: `${BASE_URL_MAIN}admin/imageListing`,
  listOfSoldImages: `${BASE_URL_MAIN}admin/listOfSoldImages`,
  listOfImagesByGroup: `${BASE_URL_MAIN}admin/listOfImagesByGroup`,
  listOfSoldImagesbyGroupIds: `${BASE_URL_MAIN}admin/listOfSoldImagesbyGroupIds`,
  updateImage: `${BASE_URL_MAIN}admin/updateImage`,
  insertImage: `${BASE_URL_MAIN}admin/insertImage`,
  bulkSuspendImages:`${BASE_URL_MAIN}admin/uploadSuspendImage`,
  suspendImage: `${BASE_URL_MAIN}admin/suspendimage`,
  listOfSoldPendingImages: `${BASE_URL_MAIN}admin/listOfPendingSoldImages`,
  repairKeywordsImage: `${BASE_URL_MAIN}admin/repairImageKeyword`,
  insertElasticImage: `${BASE_URL_MAIN}admin/insertImageElasticsearch`,
  insertElasticImageBulk: `${BASE_URL_MAIN}admin/insertBulkImageElasticsearch`,
  imageKeywordNew: `${BASE_URL_MAIN}admin/imageKeywordRelaionnewImage`,
  editTempImages: `${BASE_URL_MAIN}admin/editTempImage`,
  deleteTempImage: `${BASE_URL_MAIN}admin/deleteImage`,
  upadetCategoryShoot: `${BASE_URL_MAIN}admin/updateshootCategories`,
  mergeShootIds: `${BASE_URL_MAIN}admin/mergeShoots`,
  imageListSoldCount:`${BASE_URL_MAIN}admin/imageListSoldCount`,
  makeImageStar:`${BASE_URL_MAIN}admin/makeStarImage`,


  //videos listing api
  allVideoslist: `${BASE_URL_MAIN}videos/allVideoslist`,
  insertVideo: `${BASE_URL_MAIN}admin/insertVideo`,
  repairKeywordsVideos: `${BASE_URL_MAIN}admin/repairVideoKeyword`,
  insertElasticsearch: `${BASE_URL_MAIN}admin/insertVideoElasticsearch`,
  insertBulkElasticSearch: `${BASE_URL_MAIN}admin/insertBulkVideoElasticsearch`,
  editVideo: `${BASE_URL_MAIN}admin/editVideo`,
  videoKeywordNew: `${BASE_URL_MAIN}admin/videoKeywordRelaion`,
  suspendVideo: `${BASE_URL_MAIN}admin/suspendvideo`,
  updateVideo: `${BASE_URL_MAIN}admin/updateVideo`,

  //auto Suggestion
  autoListing: `${BASE_URL_MAIN}admin/listOfAutoSuggest`,
  editAutoSuggestion: `${BASE_URL_MAIN}admin/editAutoSuggest`,
  deleteAutoSuggestion: `${BASE_URL_MAIN}admin/deleteAutoSuggest`,
  addAutoSuggestion: `${BASE_URL_MAIN}admin/createAutoSuggest`,
  uploadSuggestionCSV: `${BASE_URL_MAIN}admin/uploadAutosuggest`,

  //irn api
  irnListing: `${BASE_URL_MAIN}admin/irnList`,
  generateIrn: `${BASE_URL_MAIN}admin/generateirn`,
  deleteIrn: `${BASE_URL_MAIN}admin/deleteirn`,

  //dashboard api
  dashboardCounts: `${BASE_URL_MAIN}admin/dashboard`,

  //upload weekly image api
  uploadWeeklyImage: `${BASE_URL_MAIN}admin/weeeklyImage`,

  //contact us and imageresearch queries api
  contactUsQueries: `${BASE_URL_MAIN}admin/contactUs`,

  //keywords api
  getKeywords: `${BASE_URL_MAIN}admin/getKeywords`,
  visibleKeyword: `${BASE_URL_MAIN}admin/visiblekeyword`,
  editVisibleKeyword: `${BASE_URL_MAIN}admin/editvisiblekeyword`,
  createVisibleKeyword: `${BASE_URL_MAIN}admin/createvisiblekeyword`,
  hiddenKeyword: `${BASE_URL_MAIN}admin/hiddenkeyword`,
  createHiddenKeyword: `${BASE_URL_MAIN}admin/createhiddenkeyword`,
  editHiddenKeyword: `${BASE_URL_MAIN}admin/edithiddenkeyword`,
  linkKeyword: `${BASE_URL_MAIN}admin/linkkeyword`,
  removeLinkKeyword: `${BASE_URL_MAIN}admin/removelinkkeyword`,
  deleteVisibleKeyword: `${BASE_URL_MAIN}admin/deleteVisibleKeyword`,
  deleteHiddenKeyword: `${BASE_URL_MAIN}admin/deleteHiddenKeyword`,
  uploadVisibleKeywords: `${BASE_URL_MAIN}admin/uploadVisibleKeywords`,
  getImageKeywordsList: `${BASE_URL_MAIN}common/imageKeywords`,
  linkKeywordsToImage: `${BASE_URL_MAIN}admin/imageKeywordRelaion`,

  //reports api
  dailySalesReport: `${BASE_URL_MAIN}order/dailySalesReport`,
  contributorReport: `${BASE_URL_MAIN}order/contributorReport`,
  getDailyReportData: `${BASE_URL_MAIN}report/dailySalesReport`,
  contributorgetReportData: `${BASE_URL_MAIN}report/contributorReportlist`,
  dailySalesEntry: `${BASE_URL_MAIN}admin/dailySales`,
  privillageList: `${BASE_URL_MAIN}admin/privilageClientlist`,
  countryListReport: `${BASE_URL_MAIN}report/ListOfCountry`,
  countryListReportUnPaid: `${BASE_URL_MAIN}report/unpaidCountryList`,
  stateListReport: `${BASE_URL_MAIN}common/listAllState`,
  stateListReportUnPaid: `${BASE_URL_MAIN}report/unpaidStateList`,
  companyListReport: `${BASE_URL_MAIN}admin/CompanyNameForOutstanding`,
  paymentStatusChange: `${BASE_URL_MAIN}report/paymentStatusChange`,
  unregisteredCompanyList: `${BASE_URL_MAIN}admin/NonRegisteredCompanyName`,
  // users & access
  listOfAdminUser: `${BASE_URL_MAIN}admin/listOfAdminUser`,
  createAdminUser: `${BASE_URL_MAIN}admin/createAdminUser`,
  editAdminUser: `${BASE_URL_MAIN}admin/editAdminUser`,
  updateLoggedinAdminPWD: `${BASE_URL_MAIN}admin/resetPassword`,
  changeAdminUserPassword: `${BASE_URL_MAIN}admin/changeAdminUserPassword`,
  changeContributorPassword: `${BASE_URL_MAIN}contributor/resetContributorPassword`,
  deleteAdminUser: `${BASE_URL_MAIN}admin/deleteAdminUser`,
  userDownloadHistory: `${BASE_URL_MAIN}admin/subscribedImagesByUser`,
  adminById: `${BASE_URL_MAIN}admin/AdminInfoById`,

  // logs api
  listOfSearchlog: `${BASE_URL_MAIN}admin/listOfSearchlog`,
  deleteLogs: `${BASE_URL_MAIN}admin/deleteLogs`,
  exportLogs: `${BASE_URL_MAIN}admin/KeywordsExcelDownload`,

  //admin and usersaccess Api
  userAccessMenu: `${BASE_URL_MAIN}admin/adminMenuAccessList`,
  giveUserAccess: `${BASE_URL_MAIN}admin/createAdminMenuAccess`,

  //CMS Apis
  getCmsData: `${BASE_URL_MAIN}common/cmsContent`,
  postCMSHomeData: `${BASE_URL_MAIN}common/addcmsContent`,
  listOfTestimonial: `${BASE_URL_MAIN}admin/listOfTestimonial`,
  editTestimonial: `${BASE_URL_MAIN}admin/editTestimonial`,
  createTestimonial: `${BASE_URL_MAIN}admin/createTestimonial`,
  deleteTestimonial: `${BASE_URL_MAIN}admin/deleteTestimonial`,
  listOfFaq: `${BASE_URL_MAIN}admin/listOfFaq`,
  createFaq: `${BASE_URL_MAIN}admin/createFaq`,
  editFaq: `${BASE_URL_MAIN}admin/editFaq`,
  deleteFaq: `${BASE_URL_MAIN}admin/deleteFaq`,
  imagePriceList: `${BASE_URL_MAIN}admin/listimagePricing`,
  downloadTimingList: `${BASE_URL_MAIN}admin/listDownloadTime`,
  horizontalImagedimention:`${BASE_URL_MAIN}admin/sampleimagelisting`,
  videoPriceList: `${BASE_URL_MAIN}admin/listvideoPricing`,
  editImagePrice: `${BASE_URL_MAIN}admin/imagePricing`,
  editDownloadTimeData: `${BASE_URL_MAIN}admin/UpdateDownloadTime`,
  editVerticalHorizontal:`${BASE_URL_MAIN}admin/sampleimageedit`,
  editVideoPrice: `${BASE_URL_MAIN}admin/videoPricing`,
  uploadImageBanners: `${BASE_URL_MAIN}admin/UploadHomeBannerImages`,
  uploadZip:`${BASE_URL_MAIN}admin/sampleimagezipupload`,
  createRejections:`${BASE_URL_MAIN}admin/createRejection`,
  listOfRejectionreasons:`${BASE_URL_MAIN}admin/listOfRejectionreasons`,
  deleteRejectionreasons:`${BASE_URL_MAIN}admin/deleteRejectionreasons`,
  editRejectionreasons:`${BASE_URL_MAIN}admin/editRejectionreasons`,

  //CRM api
  createUserFollowUps: `${BASE_URL_MAIN}order/createUserFollowup`,
  listOfFollowUpsByUserIds: `${BASE_URL_MAIN}order/listUserfollowup`,
  createOrderFollowUps: `${BASE_URL_MAIN}order/createOrderFollowup`,
  createMultipleOrderFollowUps: `${BASE_URL_MAIN}order/createMultiOrderFollowup`,
  listOfFollowUpsByOrderIds: `${BASE_URL_MAIN}order/listOrderfollowup`,
  createPaymentFollowUps: `${BASE_URL_MAIN}order/createpaymentFollowup`,
  listOfFollowUpsByOrderIdsForPayments: `${BASE_URL_MAIN}order/listpaymentfollowup`,
  userByEmail: `${BASE_URL_MAIN}admin/userByEmail`,
  userByEmailList: `${BASE_URL_MAIN}admin/userByEmailList`,
  followupByEmail: `${BASE_URL_MAIN}admin/followupByEmail`,
  userByMobile: `${BASE_URL_MAIN}admin/mobileByNumber`,
  companyByName: `${BASE_URL_MAIN}admin/companyByName`,
  companyMasterByName:`${BASE_URL_MAIN}admin/companyByMaster`,
  shortCompanyByName: `${BASE_URL_MAIN}admin/shortCompanyByName`,
  userByState: `${BASE_URL_MAIN}admin/userByState`,
  addDailySales: `${BASE_URL_MAIN}admin/addDailySales`,
  saleFollowup: `${BASE_URL_MAIN}admin/saleFollowup`,
  addSaleFollowup: `${BASE_URL_MAIN}admin/addSaleFollowup`,
  manageReport: `${BASE_URL_MAIN}report/manageReport`,
  stateListByCompanyName: `${BASE_URL_MAIN}admin/shortCompanyName`,
  associateByCompany: `${BASE_URL_MAIN}admin/associationByCompany`,
  stateListByFollowUp: `${BASE_URL_MAIN}admin/shortCompanyNameState`,
  getOustandingPaymentData: `${BASE_URL_MAIN}report/outstandingPayment`,
  editSpecialComment: `${BASE_URL_MAIN}admin/editSpecialComment`,
  updatePartialPaymentInvoice: `${BASE_URL_MAIN}admin/updateInvoicePayment`,
  updateSaleFollowup: `${BASE_URL_MAIN}admin/updateSaleFollowup`,
  stateByCompanyAndAssociation: `${BASE_URL_MAIN}admin/stateByAsscoiation`,
  userListingByStateAssociationCompany: `${BASE_URL_MAIN}admin/usersBystateAssociation`,
  listOfFollowedBy: `${BASE_URL_MAIN}admin/listOfFollowedBy`,
  orderFollowUps:`${BASE_URL_MAIN}report/OrdersInfoUsers`,

  //Model Api
  modelList: `${BASE_URL_MAIN}admin/listOfmodels`,
  modelImagesList: `${BASE_URL_MAIN}admin/listOfmodelsimages`,
  createModel: `${BASE_URL_MAIN}admin/createmodels`,
  editModel: `${BASE_URL_MAIN}admin/editmodels`,
  deleteModel: `${BASE_URL_MAIN}admin/deletemodels`,
  modelCSV: `${BASE_URL_MAIN}admin/uploadModels`,
  modelAssignCSV:`${BASE_URL_MAIN}admin/updateReleaseId`,
  modelImageAssignAndUnassign: `${BASE_URL_MAIN}admin/imageModelassigned`,
  assigneImageToModel: `${BASE_URL_MAIN}admin/assignmodeltoimage`,
  editimageReleaseId: `${BASE_URL_MAIN}admin/EditimageModelRealaseId`,
  modelsListing:`${BASE_URL_MAIN}admin/modelsListing`,
  downloadModelImages:`${BASE_URL_MAIN}admin/downloadModelImages`,

  //masterApi
  earningByCompany: `${BASE_URL_MAIN}admin/EarningByCompany`,

  //shootIds
  shootIds: `${BASE_URL_MAIN}admin/shootListing`,
  blockShootIds: `${BASE_URL_MAIN}admin/blockShootImage`,
  unblockShootIds: `${BASE_URL_MAIN}admin/unblockShootImage`,
  shootsImages: `${BASE_URL_MAIN}admin/shootImages`,
  shootsIdsSearch: `${BASE_URL_MAIN}admin/shootSearchList`,
  shootImageByShootId:`${BASE_URL_MAIN}admin/shootImagesByShootId`,
  ExportShootsImages:  `${BASE_URL_MAIN}admin/ExportShootImages`,

  // Exclusive Search For Image
  autosuggest: `${BASE_URL}autosuggest`,
  getExclusiveImagesSearchList: `${BASE_URL}Exclusiveimagesearch`,
  exclusiveImagesearchByName: `${BASE_URL}ExclusiveimagesearchByName`,
};

export default ApiConfig;
