export const ReportsData = {
    tabs: [
      {
        label: 'Daily Sales Report',
        id: 1,
      },
      {
        label: 'Contributors Report',
        id: 2,
      },
      {
        label: 'Privilege Report',
        id: 3,
      },
      {
        label: 'Multiple Report',
        id: 4,
      }
    ],
  };

export const LogsData = {
  tabs: [
    {
      label: 'All Search Logs',
      id: 1,
    },
    {
      label: 'Zero Search Logs',
      id: 2,
    }
  ],
};

export const SearchByContributorReport= [
  {
    label: 'Select',
    value: ''
  },
  {
    label: 'User Name',
    value: 'user_name',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Order ID ',
    value: 'order_id',
  },
  {
    label: 'Image Name ',
    value: 'image_name',
  },
];