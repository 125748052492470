import { Delete, Edit } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getNewParameters, getObjectSubset, getParameters } from '../../lib/helper';
import ConfirmationModal from '../Modals/ConfirmationModal';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import MatTable from '../Tables/MatTable';
import { deleteTestimonial, getTestimonial } from '../../Services/cmsService';
import { cmsItemsSelector } from '../../redux/cms';
import AddEditTestimonialModal from '../Modals/AddEditTestimonialModal';
import ApiConfig from '../../config/ApiConfig';
import defaultImage1 from '../../Asset/image/testimonial.png';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';
import { adminAddLogs } from '../../Services/commonService';

const limit = 10;
//BASE_IMAGE_URL
const column = [
  {
    label: 'Image',
    id: 'image_url',
    format: (columnData) => (
      <span
        style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}
      >
        <img style={{ width: 80, height: 80, borderRadius: '50%' }} src={columnData ? `${ApiConfig?.S3_BASE_URL}testimonials/${columnData}` : defaultImage1} />
      </span>
    ),
  },
  {
    label: 'Name',
    id: 'name',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content" }}>{columnData}</span>)
  },
  {
    label: 'Subject',
    id: 'subject',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 200, width: "max-content" }}>{columnData}</span>)
  },
  {
    label: 'Description',
    id: 'description',
    format: (columnData) => (<span style={{ display: 'inline-block', maxWidth: 400, width: "max-content" }}>{columnData || 'N/A'}</span>)
  },
];

function Testimonials() {
  const [tableListing, setTableListing] = useState([])
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, testiMonialData } = useSelector(cmsItemsSelector);
  const dispatch = useDispatch();
  const [addTestimonialModal, setAddTestimonialModal] = useState(false);
  const [editTestimonialModal, setEditTestimonalModal] = useState(false);
  const [selectedTestimonial, setSelectedTestimonal] = useState(null);
  const [confirmDeleteCategory, setConfirmDeleteCategory] = useState(false);
  const [deleteTestimonialData, setDeleteTestimonailData] = useState({});
  const navigate = useNavigate()
  const location = useLocation();

  const handleAdd = (value = false) => {
    setAddTestimonialModal(value)
  }
  const handleEdit = (value = false) => {
    setEditTestimonalModal(value);
  }

  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      ...allParams,
      ...params
    }
    dispatch(getTestimonial(payload))
    setPage(Number(payload.page))
  }

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters()
    setAllParams(params);
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams)
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false })
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page))
      }
    }
    getListing();
  }, [allParams])

  //making data format for table input
  useEffect(() => {
    if (testiMonialData?.items?.data?.length >= 0) {
      setTableListing(testiMonialData?.items?.data)
    }
  }, [testiMonialData.items]);

  const handleConfirmDeleteCategory = async (value) => {
    if (value && deleteTestimonialData) {
      const resp = await deleteTestimonial({ testimonial_id: deleteTestimonialData });
      if (resp) {
        let payloadForLogs = {
          action: action?.delete,
          module: modulesNames?.cms,
          description: logsMessagesList?.cms?.testimonialDelete,
        };
        await adminAddLogs(payloadForLogs);
        getListing({ page: '1' })
        setPage(1)
      }
    } else {
      setDeleteTestimonailData(null)
    }
  }

  const handleCofirmModalOpen = (value) => {
    setConfirmDeleteCategory(value);
  }

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: "flex" }}>
          <CustomButton className="noMinWidth buttonDense" onClick={() => {
            setSelectedTestimonal(data)
            handleEdit(true)
          }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonPrimary.main', color: 'buttonPrimary.contrastText' }}><Edit /></CustomButton>
          <CustomButton className="noMinWidth buttonDense" onClick={() => {
            handleCofirmModalOpen(true)
            setDeleteTestimonailData(data?.testimonial_id)
          }} sx={{ padding: '5px 10px', marginRight: 1, fontSize: '12px', bgcolor: 'buttonSecondary.main', color: 'buttonSecondary.contrastText' }}><Delete /></CustomButton>
        </div>
      </>
    );
  };


  return (
    <>
      <MatTable
        loading={testiMonialData?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: "",
            end_date: "",
            ...getObjectSubset(data, 'start_date', 'end_date'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        page={page}
        perPage={limit}
        total={testiMonialData?.items?.totalCount || 0}
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm
          })
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm
          })
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage)
          })
          setAllParams({
            ...allParams,
            page: String(newPage)
          })
        }}
        toolBarButton={() => {
          return <>
            <CustomButton onClick={() => { handleAdd(true) }} color={'buttonPrimary'} sx={(theme) => ({
              padding: '10px 10px', marginRight: 1,
              [theme.breakpoints.down("md")]: {
                flex: 1,
              }
            })}>Add Testimonial</CustomButton>
          </>
        }}
        toolbarEnable={true}
        pagination={true}
        filtersUrlData={allParams || {}}
        filterEnable
        rangeDateFilter
      />
      {addTestimonialModal && <AddEditTestimonialModal open={addTestimonialModal} setPage={setPage} type={'add'} handleOpen={handleAdd} />}
      {confirmDeleteCategory && <ConfirmationModal open={confirmDeleteCategory} handleConfirm={handleConfirmDeleteCategory} setOpen={handleCofirmModalOpen} />}
      {editTestimonialModal && <AddEditTestimonialModal open={editTestimonialModal} setPage={setPage} handleOpen={handleEdit} type={'edit'} data={selectedTestimonial} setSelectedTestimonal={setSelectedTestimonal} />}
    </>
  );
}

export default Testimonials;
