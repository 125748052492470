import { Grid, MenuItem, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import Divder from '../CommonComp/Divder';
import { updateUser, userDetailsApi } from '../../Services/userApis';
import { userItemsSelector } from '../../redux/user';
import UserDetailInputField from './UserDetailInputField';
import CustomCheckbox from '../CommonComp/CustomInput/CheckboxField';
import { commonItemsSelector } from '../../redux/common';
import {
  getCountry,
  getJobDescription,
  getState,
} from '../../Services/commonService';
import CustomPhoneInput from '../CommonComp/CustomInput/CustomPhoneInput';
import { DATA_LIMIT } from '../../data/constants';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { crypt, formatDate } from '../../lib/helper';
import { UserIdentityData, Select_Sez } from '../../data/userData';
import { useParams, useSearchParams } from 'react-router-dom';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import { adminAddLogs } from '../../Services/commonService';
import {
  action,
  logsMessagesList,
  modulesNames,
} from '../../data/logsMessages';
const limit = DATA_LIMIT;
function UserAllDetail({}) {
  const { userId } = useParams();
  const { singleUserDetail } = useSelector(userItemsSelector);
  const { jobData, countryData, stateData } = useSelector(commonItemsSelector);

  const dispatch = useDispatch();
  const [jobDesc, setJobDesc] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [countryShortName, setCountryShortName] = useState('in');
  const [stateList, setStateList] = useState([]);
  const [selectedCountry, setSelcetedCountry] = useState('');
  const [selectedState, setSelectedState] = useState({});
  const navigate = useNavigate();
  const handleConfirm = async (values, { resetForm }) => {
    if (values.email) {
      let payload = {
        // ...values,

        email: values?.email || '',
        user_name: values?.user_name?.trim() || '',
        first_name: values?.first_name?.trim(),
        last_name: values?.last_name?.trim() || '',
        job_description: values?.job_description?.trim() || '',
        company_name: values?.company_name?.trim(),
        business_type: values?.business_type?.trim() || '',
        country: values?.country?.trim() || '',
        address: values?.address?.trim() || '',
        state: values?.state?.trim() || '',
        zip_code: values?.zip_code?.trim() || '',
        phone: values?.phone?.trim() || '',
        status: values?.status || '0',
        cc_mail: values?.cc_mail?.trim() || '',
        commission: values?.commission?.trim() || '',
        subscription_discount: values?.subscription_discount || '0',
        subscription_mail: values?.subscription_mail?.trim() || '',
        ip_address: values?.ip_address?.trim() || '',
        High_resolution: values?.items || '0',
        image_limit: values?.image_limit || '',
        pan_number: values?.pan_number?.trim() || '',
        gst_number: values?.gst_number?.trim() || '',
        ivs_download: values?.ivs_download || '0',
        privellage_status: values?.privellage_status || '0',
        is_subscribed: values?.is_subscribed?.trim() || '',
        identity: values?.identity?.trim() || '',
        otp_verify: values?.otp_verify || '0',
        remarks: values?.remarks?.trim() || '',
        gift_policy: values?.gift_policy || '0',
        sez_status: values?.sez_status || '0',
        user_id: userId,
        discount: values?.discount || '0',
        mobile: values?.mobile?.includes('+')
          ? values?.mobile
          : '+' + values?.mobile,
        isd_number: values.isd_number == '1' ? 1 : 0,
        identity_date: values.identity_date
          ? values?.identity_date
          : formatDate(new Date(), 'yyyy-mm-ddthh:mm'),
      };
      let resp = await updateUser(payload);
      if (resp?.status === 200) {
        let payloadForLogs = {
                  action: action?.update,
                  module: modulesNames?.users,
                  description: logsMessagesList?.users?.userEdit,
        };
        await adminAddLogs(payloadForLogs);
        navigate('/user');
      }
    }
  };

  let gstValidationSchema = {};
  //
  //06AAACA2793H1ZS
  if (selectedCountry == 'India') {
    gstValidationSchema = {
      gst_number: Yup.string()
        .trim()
        .matches(
          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}/,
          'Please enter valid GST Number',
        )
        .test(
          'isValidGST',
          'Please enter correct GST Number',
          function (value) {
            let isValid = value?.startsWith(
              selectedState?.state_code?.toString().length > 1
                ? selectedState?.state_code
                : `0${selectedState?.state_code}`,
            );
            return value === '' || value === null || value === undefined
              ? true
              : isValid;
          },
        ),
    };
  }

  const formik = useFormik({
    initialValues: {
      email: singleUserDetail?.items?.email?.trim() || '',
      user_name: singleUserDetail?.items?.user_name || '',
      first_name: singleUserDetail?.items?.first_name || '',
      last_name: singleUserDetail?.items?.last_name || '',
      job_description: singleUserDetail?.items?.job_descriptions
        ? singleUserDetail?.items?.job_descriptions?.name
        : '',
      company_name: singleUserDetail?.items?.company_name || '',
      business_type: singleUserDetail?.items?.business_type || '',
      country: singleUserDetail?.items?.countries
        ? singleUserDetail?.items?.countries?.country_name
        : '',
      address: singleUserDetail?.items?.address || '',
      state: singleUserDetail?.items?.states
        ? singleUserDetail?.items?.states?.state_name
        : '',
      zip_code: singleUserDetail?.items?.zip_code || '',
      phone: singleUserDetail?.items?.phone || '',
      mobile: singleUserDetail?.items?.mobile || '',
      discount: singleUserDetail?.items?.discount || '',
      status: singleUserDetail?.items?.status
        ? singleUserDetail?.items?.status
        : '0',
      cc_mail: singleUserDetail?.items?.cc_mail || '',
      commission: singleUserDetail?.items?.commission || '',
      subscription_discount: singleUserDetail?.items?.subscription_discount
        ? singleUserDetail?.items?.subscription_discount
        : '0',
      subscription_mail: singleUserDetail?.items?.subscription_mail || '',
      ip_address: singleUserDetail?.items?.ip_address || '',
      High_resolution: singleUserDetail?.items?.High_resolution
        ? singleUserDetail?.items?.High_resolution
        : '0',
      image_limit: singleUserDetail?.items?.image_limit || '',
      pan_number: singleUserDetail?.items?.pan_number || '',
      gst_number: singleUserDetail?.items?.gst_number || '',
      isd_number: singleUserDetail?.items?.isd_number || '',
      ivs_download: singleUserDetail?.items?.ivs_download
        ? singleUserDetail?.items?.ivs_download
        : '0',
      privellage_status: singleUserDetail?.items?.privellage_status
        ? singleUserDetail?.items?.privellage_status
        : '0',
      is_subscribed: singleUserDetail?.items?.is_subscribed || '',
      identity: singleUserDetail?.items?.identity || '',
      identity_date: singleUserDetail?.items?.identity_date || null,
      otp_verify: singleUserDetail?.items?.otp_verify
        ? singleUserDetail?.items?.otp_verify
        : '0',
      remarks: singleUserDetail?.items?.remarks || '',
      gift_policy: singleUserDetail?.items?.gift_policy || '0',
      sez_status: singleUserDetail?.items?.sez_status || '0',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Please enter Email')
        .required('Please enter Email'),
      // password: Yup.string().required('Please Enter your password'),
      mobile: Yup.string()
        .max(18, 'Mobile Number is Invalid')
        .min(10, 'Mobile Number is Invalid'),
      phone: Yup.string()
        .matches(/^[\d -]+$/, 'It should be number only.')
        .max(50, 'Max length will be 50.'),
      discount: Yup.string().matches(
        /^([0-9]\.[0-9]{1}|[0-9]\.[0-9]{2}|\.[0-9]{2}|[1-9][0-9]\.[0-9]{1}|[1-9][0-9]\.[0-9]{2}|[0-9][0-9]|[1-9][0-9]\.[0-9]{2})$|^([0-9]|[0-9][0-9]|[0-99])$|^100$/gm,
        'Value must be number and should be in range of [0 - 100] with allowed upto two decimals.',
      ),

      // // .min(5, 'Must be exactly 5 digits')

      // // .max(5, 'Must be exactly 5 digits')

      // .max(1,'Value should not be greater than 100')

      // .min(0,"Value should not be lesser than 0"),
      ...gstValidationSchema,
    }),
    onSubmit: handleConfirm,
  });

  const getUserDetails = async () => {
    let payload = {
      user_id: userId,
      page: String(1),
      limit: limit - 5,
    };
    let listPayload = {
      page: String(1),
      limit: limit - 5,
    };
    let outstandingPayload = {
      page: String(1),
      limit: String(limit - 5),
      user_id: userId,
      order_status: '1',
      payment_status: '0',
      ivs_order: false,
    };
    let downloadPayload = {
      page: String(1),
      limit: String(limit - 5),
      user_id: userId,
    };
    dispatch(userDetailsApi(payload));
  };

  const getStateData = async (user) => {
    if (user?.items?.countries) {
      dispatch(getState(user?.items?.countries?.country_id));
    }
  };

  useEffect(() => {
    if (Object.keys(singleUserDetail?.items || {})?.length) {
      setSelcetedCountry(singleUserDetail?.items?.country || '');
      setSelectedState(singleUserDetail?.items?.states);
    }
  }, [singleUserDetail]);

  useEffect(() => {
    if (jobData && Array.isArray(jobData?.items)) {
      let data = jobData?.items?.map((item) => {
        return {
          label: item?.name,
          value: item?.job_description_id,
        };
      });
      setJobDesc(data);
    }
  }, [jobData]);

  useEffect(() => {
    if (countryData && Array.isArray(countryData?.items)) {
      let data = countryData?.items?.map((item) => {
        return {
          label: item?.country_name,
          value: item?.country_id,
        };
      });
      setCountryList(data);
      getStateData(singleUserDetail);
      if (singleUserDetail?.items?.countries) {
        setCountryShortName(
          singleUserDetail?.items?.countries?.country_short_name?.toLowerCase(),
        );
      }
    }
  }, [countryData, singleUserDetail]);

  useEffect(() => {
    if (stateData && Array.isArray(stateData?.items)) {
      let data = stateData?.items?.map((item) => {
        return {
          label: item?.state_name,
          value: item?.state_id,
        };
      });
      setStateList(data);
    }
  }, [stateData]);

  const getJobDescriptionDetails = async () => {
    dispatch(getJobDescription());
  };

  const getCountryDetails = async () => {
    dispatch(getCountry());
  };

  const handleChangeCountry = async (e) => {
    formik.setFieldValue('country', e.target.value);
    formik.setFieldValue('state', '');
    setSelcetedCountry(e.target.value || '');
    setSelectedState({});
    let country_id = 0;
    countryData?.items?.filter((val) => {
      if (val.country_name === e.target.value) {
        country_id = val.country_id;
        setCountryShortName(val?.country_short_name?.toLowerCase());
        formik.setFieldValue('mobile', '');
      }
    });
    dispatch(getState(country_id));
  };

  const handleStateChange = (val) => {
    let stateSelected = stateData?.items?.filter(
      (item) => item.state_name == val,
    );
    if (stateSelected.length) {
      setSelectedState(stateSelected[0]);
    }
  };

  useEffect(() => {
    formik.setFieldTouched('gst_number', true, true);
  }, [selectedState]);

  const handleChangeMobile = (val) => {
    formik.setFieldValue('mobile', val);
  };

  useEffect(() => {
    getUserDetails();
    getJobDescriptionDetails();
    getCountryDetails();
    // getIvsData()
  }, []);

  const handleChangeIdentity = (e) => {
    formik.setValues((values) => ({
      ...values,
      identity: e.target.value,
      identity_date:
        singleUserDetail?.items?.identity_date &&
        singleUserDetail?.items?.identity == e.target.value
          ? singleUserDetail?.items?.identity_date
          : formatDate(new Date(), 'yyyy-mm-ddthh:mm'),
    }));
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <CustomBox padding={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <UserDetailInputField
                name="email"
                label="Email"
                placeholder="Email"
                disabled
                value={formik.values.email}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <UserDetailInputField
                name="first_name"
                label="First Name"
                placeholder="First Name"
                value={formik.values.first_name}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={(e) => {
                  formik.setFieldValue('first_name', e.target.value);
                }}
                error={
                  formik.touched.first_name && Boolean(formik.errors.first_name)
                }
                helperText={
                  formik.touched.first_name && formik.errors.first_name
                }
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <UserDetailInputField
                name="last_name"
                label="Last Name"
                placeholder="Last Name"
                value={formik.values.last_name}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={(e) => {
                  formik.setFieldValue('last_name', e.target.value);
                }}
                error={
                  formik.touched.last_name && Boolean(formik.errors.last_name)
                }
                helperText={formik.touched.last_name && formik.errors.last_name}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <UserDetailInputField
                name="job_description"
                label="Job Description"
                placeholder="Job Description"
                SelectProps={{ native: true }}
                select
                value={formik.values.job_description}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={formik.handleChange}
                error={
                  formik.touched.job_description &&
                  Boolean(formik.errors.job_description)
                }
                helperText={
                  formik.touched.job_description &&
                  formik.errors.job_description
                }
              >
                <option key={'Select Job Description'} value={''}>
                  Select Job Description
                </option>
                {jobDesc &&
                  jobDesc?.map((role) => (
                    <option key={role.value} value={role.label}>
                      {role.label}
                    </option>
                  ))}
              </UserDetailInputField>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <UserDetailInputField
                name="company_name"
                label="Company Name"
                placeholder="Company Name"
                value={formik.values.company_name}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: { notchedOutline: 'rounded' },
                }}
                onChange={(e) => {
                  formik.setFieldValue('company_name', e.target.value);
                }}
                error={
                  formik.touched.company_name &&
                  Boolean(formik.errors.company_name)
                }
                helperText={
                  formik.touched.company_name && formik.errors.company_name
                }
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <UserDetailInputField
                name="business_type"
                label="Business Type"
                placeholder="Business Type"
                value={formik.values.business_type}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: { notchedOutline: 'rounded' },
                }}
                onChange={(e) => {
                  formik.setFieldValue('business_type', e.target.value);
                }}
                error={
                  formik.touched.business_type &&
                  Boolean(formik.errors.business_type)
                }
                helperText={
                  formik.touched.business_type && formik.errors.business_type
                }
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <UserDetailInputField
                name="country"
                label="Country"
                id="country"
                placeholder="Country"
                SelectProps={{ native: true }}
                select
                value={formik.values.country}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={(e) => handleChangeCountry(e)}
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={formik.touched.country && formik.errors.country}
              >
                <option key={'Select Country'} value={''}>
                  Select Country
                </option>

                {countryList &&
                  countryList?.map((country) => (
                    <option key={country.value} value={country.label}>
                      {country.label}
                    </option>
                  ))}
              </UserDetailInputField>
            </Grid>

            {stateData.isStateAvailable === true ? (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <UserDetailInputField
                  name="state"
                  label="State"
                  placeholder="State"
                  SelectProps={{
                    native: true,
                  }}
                  select
                  value={formik.values.state}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleStateChange(e.target.value);
                  }}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  helperText={formik.touched.state && formik.errors.state}
                >
                  <option key={'Select State'} value={''}>
                    {formik.values.country
                      ? 'Select state'
                      : 'Please select country first'}
                  </option>
                  {stateList &&
                    stateList?.map((state) => {
                      return (
                        <option key={state.value} value={state.label}>
                          {state.label}
                        </option>
                      );
                    })}
                </UserDetailInputField>
              </Grid>
            ) : null}

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <UserDetailInputField
                name="address"
                label="Address"
                placeholder="Address"
                multiline
                maxRows={4}
                value={formik.values.address}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={(e) => {
                  formik.setFieldValue('address', e.target.value);
                }}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <UserDetailInputField
                name="zip_code"
                label="Zip / Pin Code"
                placeholder="Zip / Pin Code"
                value={formik.values.zip_code}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={(e) => {
                  formik.setFieldValue('zip_code', e.target.value);
                }}
                error={
                  formik.touched.zip_code && Boolean(formik.errors.zip_code)
                }
                helperText={formik.touched.zip_code && formik.errors.zip_code}
                inputProps={{ maxLength: 15 }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <UserDetailInputField
                name="phone"
                label="Phone"
                placeholder="Phone"
                value={formik.values.phone}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={(e) => {
                  formik.setFieldValue('phone', e.target.value);
                }}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomPhoneInput
                label="Mobile"
                id="mobile"
                name="mobile"
                className="main-login-form"
                fullWidth
                placeholder="Mobile Number"
                native={true}
                value={formik.values.mobile}
                defaultCountry={`${countryShortName}`}
                country={`${countryShortName}`}
                divStyle={{
                  paddingTop: 4,
                }}
                variant="outlined"
                onChange={(newValue, info) => {
                  handleChangeMobile(newValue);
                }}
                onlyCountries={[countryShortName]}
                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                helperText={formik.touched.mobile && formik.errors.mobile}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <UserDetailInputField
                name="discount"
                label="User Discount(%)"
                placeholder="User Discount"
                value={formik.values.discount}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={(e) => {
                  if (Number(e?.target?.value) >= 0) {
                    formik.setFieldValue('discount', e?.target?.value);
                  } else {
                    toast.error('Please enter valid numbers.');

                    formik.setFieldValue('discount', '0');
                  }
                }}
                onBlur={(e) => {
                  if (!e?.target?.value) {
                    formik.setFieldValue('discount', '0');
                  }
                }}
                error={
                  formik.touched.discount && Boolean(formik.errors.discount)
                }
                helperText={formik.touched.discount && formik.errors.discount}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomCheckbox
                name="status"
                label={'User Status'}
                labelPlacement={'end'}
                subLableText={'Active'}
                value={formik.values.status == 1 ? true : false}
                handleChange={(e) => {
                  formik.setFieldValue(
                    'status',

                    e.target.checked == true ? '1' : '0',
                  );
                }}
                error={formik.touched.status && Boolean(formik.errors.status)}
                helperText={formik.touched.status && formik.errors.status}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <UserDetailInputField
                name="cc_mail"
                label="CC Mail"
                placeholder="CC Mail"
                multiline
                maxRows={4}
                value={formik.values.cc_mail}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={(e) => {
                  formik.setFieldValue('cc_mail', e.target.value);
                }}
                error={formik.touched.cc_mail && Boolean(formik.errors.cc_mail)}
                helperText={formik.touched.cc_mail && formik.errors.cc_mail}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomCheckbox
                name="subscription_discount"
                label={'Subscription Plan Discount'}
                labelPlacement={'end'}
                subLableText={'Active'}
                value={formik.values.subscription_discount == 1 ? true : false}
                handleChange={(e) => {
                  formik.setFieldValue(
                    'subscription_discount',

                    e.target.checked == true ? '1' : '0',
                  );
                }}
                error={
                  formik.touched.subscription_discount &&
                  Boolean(formik.errors.subscription_discount)
                }
                helperText={
                  formik.touched.subscription_discount &&
                  formik.errors.subscription_discount
                }
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <UserDetailInputField
                name="subscription_mail"
                label="Subscription CC Mail"
                placeholder="Subscription CC Mail"
                multiline
                maxRows={4}
                value={formik.values.subscription_mail}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={(e) => {
                  formik.setFieldValue('subscription_mail', e.target.value);
                }}
                error={
                  formik.touched.subscription_mail &&
                  Boolean(formik.errors.subscription_mail)
                }
                helperText={
                  formik.touched.subscription_mail &&
                  formik.errors.subscription_mail
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <UserDetailInputField
                name="pan_number"
                label="Pan Number"
                placeholder="Pan Number"
                value={formik.values.pan_number}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={(e) => {
                  formik.setFieldValue('pan_number', e.target.value);
                }}
                error={
                  formik.touched.pan_number && Boolean(formik.errors.pan_number)
                }
                helperText={
                  formik.touched.pan_number && formik.errors.pan_number
                }
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <UserDetailInputField
                name="gst_number"
                label="GSTIN No."
                placeholder="GSTIN No."
                value={formik.values.gst_number}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                onChange={(e) => {
                  formik.setFieldTouched('gst_number', true, true);

                  formik.setFieldValue(
                    'gst_number',

                    e.target.value.toUpperCase(),
                  );
                }}
                inputProps={{
                  maxLength: 15,
                }}
                error={
                  formik.touched.gst_number && Boolean(formik.errors.gst_number)
                }
                helperText={
                  formik.touched.gst_number && formik.errors.gst_number
                }
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomCheckbox
                name="isd_number"
                label={'ISD Number'}
                labelPlacement={'end'}
                subLableText={'Active'}
                value={formik.values?.isd_number == 1 ? true : false}
                handleChange={(e) => {
                  formik.setFieldValue(
                    'isd_number',

                    e.target.checked == true ? '1' : '0',
                  );
                }}
                error={
                  formik.touched.isd_number && Boolean(formik.errors.isd_number)
                }
                helperText={
                  formik.touched.isd_number && formik.errors.isd_number
                }
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomCheckbox
                name="ivs_download"
                label={'Action For IVS Download'}
                labelPlacement={'end'}
                subLableText={'Active'}
                value={formik.values.ivs_download == 1 ? true : false}
                handleChange={(e) => {
                  formik.setFieldValue(
                    'ivs_download',

                    e.target.checked == true ? '1' : '0',
                  );
                }}
                error={
                  formik.touched.ivs_download &&
                  Boolean(formik.errors.ivs_download)
                }
                helperText={
                  formik.touched.ivs_download && formik.errors.ivs_download
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomCheckbox
                name="otp_verify"
                label={'Verify Email'}
                labelPlacement={'end'}
                subLableText={'Active'}
                value={formik.values.otp_verify == '1' ? true : false}
                handleChange={(e) => {
                  // if (formik.values.otp_verify == "0") {
                  formik.setFieldValue(
                    'otp_verify',
                    e.target.checked == true ? '1' : '0',
                  );
                  // }
                }}
                error={
                  formik.touched.otp_verify && Boolean(formik.errors.otp_verify)
                }
                helperText={
                  formik.touched.otp_verify && formik.errors.otp_verify
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <UserDetailInputField
                select
                SelectProps={{
                  native: true,
                }}
                name="identity"
                label="Identity"
                placeholder="Identity"
                value={formik.values.identity}
                onChange={(e) => {
                  handleChangeIdentity(e);
                }}
                error={
                  formik.touched.identity && Boolean(formik.errors.identity)
                }
                helperText={formik.touched.identity && formik.errors.identity}
              >
                {UserIdentityData &&
                  UserIdentityData?.map((option) => (
                    <option key={option.value} value={option.label}>
                      {option.label}
                    </option>
                  ))}
              </UserDetailInputField>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomCheckbox
                name="privellage_status"
                label={'Is User Privilege'}
                labelPlacement={'end'}
                subLableText={'Active'}
                value={formik.values.privellage_status == 1 ? true : false}
                handleChange={(e) => {
                  formik.setFieldValue(
                    'privellage_status',

                    e.target.checked == true ? '1' : '0',
                  );
                }}
                error={
                  formik.touched.privellage_status &&
                  Boolean(formik.errors.privellage_status)
                }
                helperText={
                  formik.touched.privellage_status &&
                  formik.errors.privellage_status
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <UserDetailInputField
              name="sez_status"
              label="Selected Sez"
              placeholder="Selected Sez"
              SelectProps={{ native: true }}
              select
              value={formik.values.sez_status}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                classes: {
                  notchedOutline: 'rounded',
                },
              }}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              error={
                formik.touched.sez_status &&
                Boolean(formik.errors.sez_status)
              }
              helperText={
                formik.touched.sez_status && formik.errors.sez_status
              }
            >
              {Select_Sez.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
                
              </UserDetailInputField>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="remarks"
                label="Remarks"
                placeholder="Remarks"
                value={formik.values.remarks}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: 'rounded',
                  },
                }}
                multiline
                rows={3}
                // maxRows={3}
                error={formik.touched.remarks && Boolean(formik.errors.remarks)}
                helperText={formik.touched.remarks && formik.errors.remarks}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <CustomCheckbox
                name="gift_policy"
                label={'Gift Policy'}
                labelPlacement={'end'}
                subLableText={'Active'}
                value={formik.values.gift_policy == 1 ? true : false}
                handleChange={(e) => {
                  formik.setFieldValue(
                    'gift_policy',

                    e.target.checked == true ? '1' : '0',
                  );
                }}
                error={
                  formik.touched.gift_policy &&
                  Boolean(formik.errors.gift_policy)
                }
                helperText={
                  formik.touched.gift_policy && formik.errors.gift_policy
                }
              />
            </Grid>

            

            <Grid item xs={12} textAlign="right">
              <CustomButton type="submit" color={'buttonPrimary'}>
                Submit
              </CustomButton>
            </Grid>
          </Grid>
        </CustomBox>
      </form>

      <Divder spacing={1} />
    </div>
  );
}

export default UserAllDetail;
