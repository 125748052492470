import { Close, Delete } from '@material-ui/icons';
import { Box, Typography } from '@mui/material';
import React, { useRef, useState } from 'react'
import { errorMessage, humanize } from '../../lib/helper';
import { adminAddLogs, appSettingApi, fileUploadImage, updateSettingApi } from '../../Services/commonService';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import ApiConfig from '../../config/ApiConfig';
import { deleteBannerImage, getBannersInfo } from '../../Services/cmsService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from '../Modals/ConfirmationModal';
import AllConstant from '../../AllConstant';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';

function ImageUploadBanners({ item, parentKey,total,handleLast }) {

    const [imageData, setImageData] = useState(item?.image_name);
    const [loading, setLoading] = useState(false);
    const [selectedImageData, setSelectedImageData] = useState(item?.key_value);
    const [confirmDelete,setConfirmDelete]=useState(false);
    const imageInputRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImageData(URL.createObjectURL(event.target.files[0]));
            let fileName = event.target.files[0].name.split('.');
            fileName = fileName[fileName?.length - 1]
            let newFile = new File([event.target.files[0]], `${item.key_name}.${fileName || 'jpeg'}`, { type: event.target.files[0].type });
            setSelectedImageData(newFile)
        }
    }
    const handleSubmit = async () => {
        setConfirmDelete(true);
       
    }
    const handleDelete=async(open)=>{
        if(open){
        if (total < 2) {
            toast.error(("Atleast 1 Banner image is required."), {
                autoClose: 2500,
              });
        }
        else {
            let payload = { image_name: item?.image_name, folder_name: 'bannersImages' }
            if (payload) {
                const resp = await deleteBannerImage(payload);
                if (resp?.statusCode == 200) {
                     let payloadForLogs = {
                            action: action?.delete,
                            module: modulesNames?.cms,
                            description: logsMessagesList?.cms?.bannerImageDeleted
                          };
                          await adminAddLogs(payloadForLogs);
                    handleLast();
                }
            }
        }
    }
    else{
        setConfirmDelete(false)
    }
    }

    const handleDeleteAction=(val)=>{
        if(val){
        setConfirmDelete(val)}
    }


    return (<>
        <Typography variant='h5'  >{humanize(imageData)}</Typography>
        <div className='borderBottom-1'></div>
        <Box sx={(theme) => ({
            display: "flex",
        })} className="my3">
            <div className='' style={{ position: 'relative' }}>
                <CustomButton variant="contained" component="label" sx={{ padding: 0 }}>
                    <img id={`${imageData}`} src={`${ApiConfig?.S3_BASE_URL}bannersImages/` + imageData} style={{ maxWidth: 500 }} className="img-fluid rounded-2" />
                </CustomButton>
            </div>
        </Box>
        <Box textAlign={'right'}>
            <CustomButton type="button" onClick={() => { handleSubmit() }} loading={loading} color={'buttonDanger'} sx={{ padding: '8px 20px' }}>Delete</CustomButton>
        </Box>
        {confirmDelete && <ConfirmationModal open={confirmDelete} handleConfirm={handleDelete} setOpen={handleDeleteAction} confirmationDesc={AllConstant?.alertMessages?.bannerDeleteconfirmMessage} />}
    </>
    )
}

export default ImageUploadBanners