
import React, { useEffect, useState } from 'react';
import TechnicalDownloadTime from './TechnicalDownloadTime';
import TechnicalHorizontalDimension from './TechnicalHorizontalDimension';
import TechnicalVerticalDimension from './TechnicalVerticalDimension';
function TechnicalPage() {
  return (
    <>
    <TechnicalDownloadTime/>
    <TechnicalHorizontalDimension/>
    <TechnicalVerticalDimension/>
    </>
  );
}

export default TechnicalPage;
