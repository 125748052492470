import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import { DATA_LIMIT } from '../../data/constants';
import { createVideoCategory, editVideoCategory, videoCategoryListings } from '../../Services/categoryApis';
import {
  action,
  logsMessagesList,
  modulesNames,
} from '../../data/logsMessages';
import { adminAddLogs } from '../../Services/commonService';

const limit = DATA_LIMIT;

function AddEditVideoCategoryModal({ open, handleOpen, data, setPage, type, setSelectedCategory }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true)
    let resp = false;
    if (type == 'add') {
      resp = await createVideoCategory({ ...values, sort_id: Number(values?.sort_id) })
      let payloadForLogs = {
                      action: action?.add,
                      module: modulesNames?.categories,
                      description: logsMessagesList?.categories?.categoriesVideoCategoryAdd,
              };
      await adminAddLogs(payloadForLogs);
    }
    if (type == 'edit') {
      resp = await editVideoCategory({ ...values, category_id: data?.category_id, sort_id: Number(values?.sort_id) })
      let payloadForLogs = {
                action: action?.update,
                module: modulesNames?.categories,
                description: logsMessagesList?.categories?.categoriesVideoCategoryEdit,
      };
      await adminAddLogs(payloadForLogs);
    }
    if (resp) {
      resetForm({
        category_name: '',
        search_id: '',
        display_name: '',
        sort_id: '',
      })
      dispatch(videoCategoryListings({
        page: String(1),
        limit: limit,
      }))
      setPage(1)
      if (setSelectedCategory) {
        setSelectedCategory()
      }
      handleOpen(false)
    }
    setLoading(false)
  };

  const formik = useFormik({
    initialValues: {
      category_name: data?.category_name || '',
      search_id: data?.search_id || '',
      display_name: data?.display_name || '',
      sort_id: Number(data?.sort_id) || '',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      category_name: Yup.string().required('Category name is required.'),
      search_id: Yup.string().required('Search id is required.'),
      display_name: Yup.string().required('Display name is required.'),
      sort_id: Yup.string().matches(/^[0-9]+$/, "Must be only digits").required('Sort Id is required.'),
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'sm'}
        open={open}
        fullWidth
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          {type == 'add' ? "Add Video Category" : "Edit Video Category"}
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="category_name"
                  label="Category Name"
                  placeholder="Category Name"
                  value={formik.values.category_name}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.category_name && Boolean(formik.errors.category_name)}
                  helperText={formik.touched.category_name && formik.errors.category_name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="search_id"
                  label="Search ID"
                  placeholder="Search ID"
                  value={formik.values.search_id}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.search_id && Boolean(formik.errors.search_id)}
                  helperText={formik.touched.search_id && formik.errors.search_id}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="display_name"
                  label="Display Name"
                  placeholder="Display Name"
                  value={formik.values.display_name}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.display_name && Boolean(formik.errors.display_name)}
                  helperText={formik.touched.display_name && formik.errors.display_name}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="sort_id"
                  label="Sort ID"
                  placeholder="Sort ID"
                  value={formik.values.sort_id}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.sort_id && Boolean(formik.errors.sort_id)}
                  helperText={formik.touched.sort_id && formik.errors.sort_id}
                />
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                {type == 'add' ? 'Add' : 'Update'}
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    category_name: '',
                    search_id: '',
                    display_name: '',
                    sort_id: '',
                  });
                  handleOpen(false)
                }
                }
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddEditVideoCategoryModal;
