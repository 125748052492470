import { IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  breakStringIntoLines,
  downloadBlobXLSFile,
  formatDate,
  getNewParameters,
  getObjectSubset,
  getParameters,
} from '../../lib/helper';
import { logsItemsSelector } from '../../redux/logs';
import MatTable from '../Tables/MatTable';
import {
  deleteLogs,
  getAllLogsListing,
  getLogsListingExportFile,
} from '../../Services/logsApis';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { Delete } from '@material-ui/icons';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationModal from '../Modals/ConfirmationModal';
import { LogsSortData } from '../../data/logsData';
import {
  action,
  logsMessagesList,
  modulesNames,
} from '../../data/logsMessages';
import { adminAddLogs } from '../../Services/commonService';
import AllFilters from '../Order/AllFilters';

// const limit = 10;

const column = [
  {
    label: 'ID',
    id: 'search_id',
    format: (columnData) => (
      <Typography variant="span" style={{ whiteSpace: 'nowrap' }}>
        {columnData}
      </Typography>
    ),
  },
  {
    label: 'Keywords',
    id: 'keywords',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData ? breakStringIntoLines(columnData, 20) : 'N/A'}
      </span>
    ),
  },
  {
    label: 'Search Count',
    id: 'total_count',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
    nextLine: true,
  },
  {
    label: 'Type',
    id: 'type',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData === '1' ? 'Image' : 'Video'}
      </span>
    ),
  },
  {
    label: 'Total Record',
    id: 'total_record',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {columnData}
      </span>
    ),
  },
  {
    label: 'Created At',
    id: 'created_at',
    format: (columnData) => (
      <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
        {formatDate(columnData, 'dd/mm/yyyy')}
      </span>
    ),
  },
];

function AllSearchLog() {
  const [tableListing, setTableListing] = useState([]);
  const [page, setPage] = useState(1);
  const [allParams, setAllParams] = useState(getParameters());
  const { loading, allLogs } = useSelector(logsItemsSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [deleteLogsData, setDeleteLogsData] = useState({});
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmAllDelete, setConfirmAllDelete] = useState(false);
  const [expLoading, setExpLoading] = useState(false);
  const [limit, setLimit] = useState(20);

  const [filtersArray, setFiltersArray] = useState([
    {
      label: 'From Date',
      id: 'start_date',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
    {
      label: 'To Date',
      id: 'end_date',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e.target.value;
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: { type: 'date' },
      isVisible: true,
    },
    {
      label: 'Sort By',
      id: 'sort_by',
      type: 'select',
      linkedWith: null,
      value: '',
      onChange: (e, index, value) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e?.target?.value;
          newState[1].value = '';
          return newState;
        });
      },
      style: {},
      dataArr: LogsSortData,
      props: {},
      isVisible: true,
      rowFlexible: true,
      row: 3,
    },
    {
      label: 'Search Text',
      id: 'search',
      type: 'input',
      linkedWith: null,
      value: '',
      onChange: (e, index) => {
        setFiltersArray((prevState) => {
          let newState = [...prevState];
          newState[index].value = e?.target?.value?.trim();
          return newState;
        });
      },
      style: {},
      dataArr: [],
      props: {},
      isVisible: true,
    },
  ]);

  const handleFilter = (data, allDatavalues, dataWithValue) => {
    getListing(
      {
        page: String(1),
        ...dataWithValue,
      },
      true,
    );
    setAllParams({
      page: String(1),
      ...dataWithValue,
    });
  };

  const handleClear = () => {
    const data = getObjectSubset({ ...allParams }, 'tab', 'page');
    setAllParams(data);
    setFiltersArray((prevState) => {
      let newState = [...prevState];
      newState[0].isVisible = true;
      newState[0].value = '';
      newState[1].isVisible = true;
      newState[1].value = '';
      newState[2].isVisible = true;
      newState[2].value = '';
      newState[3].isVisible = true;
      newState[3].value = '';

      return newState;
    });
  };
  const getListing = async (params = {}) => {
    const payload = {
      page: String(1),
      limit: limit,
      // order_status: String(1),
      type: '2',
      ...allParams,
      ...params,
    };
    if (payload?.start_date && payload?.end_date) {
      if (payload?.start_date) {
        payload['start_date'] =
          formatDate(payload?.start_date, 'yyyy-mm-dd') + '  00:00:00';
      }
      if (payload?.end_date) {
        payload['end_date'] =
          formatDate(payload?.end_date, 'yyyy-mm-dd') + '  23:59:59';
      }
    }
    dispatch(getAllLogsListing(payload));
    setPage(Number(payload.page));
  };

  useEffect(() => {
    getListing();
  }, []);

  useEffect(() => {
    let params = getParameters();
    setAllParams(params);
  }, [location?.search]);

  useEffect(() => {
    let data = getNewParameters(allParams);
    if (data?.search !== location.search) {
      navigate(data.newUrl, { replace: false });
      if (data?.parameters?.page) {
        setPage(Number(data?.parameters?.page));
      }
    }
    getListing();
  }, [allParams]);

  const handleConfirmDelete = async (value) => {
    if (value && deleteLogsData) {
      const resp = await deleteLogs({ log_id: deleteLogsData?.search_id });
      if (resp) {
        let payloadForLogs = {
          action: action?.delete,
          module: modulesNames?.logs,
          description: logsMessagesList?.searchLogs?.searchLogsDelete,
        };
        await adminAddLogs(payloadForLogs);
        getListing({ page: '1' });
        setPage(1);
      }
    } else {
      setDeleteLogsData(null);
    }
  };

  const handleConfirmAllDelete = async (value) => {
    if (value) {
      const resp = await deleteLogs({ flag: 'all', type: '2' });
      if (resp) {
        let payloadForLogs = {
          action: action?.delete,
          module: modulesNames?.logs,
          description: logsMessagesList?.searchLogs?.searchLogsAllDelete,
        };
        await adminAddLogs(payloadForLogs);
        getListing({ page: '1' });
        setPage(1);
      }
    } else {
      setDeleteLogsData(null);
    }
  };
  //making data format for table input
  useEffect(() => {
    if (allLogs?.items?.data?.length >= 0) {
      setTableListing(allLogs?.items?.data);
    }
  }, [allLogs.items]);

  const getExportFile = async (params = {}) => {
    try {
      let payload = {
        ...allParams,
        type: '2',
      };
      setExpLoading(true);
      if (payload?.start_date && payload?.end_date) {
        if (payload?.start_date) {
          payload['start_date'] =
            formatDate(payload?.start_date, 'yyyy-mm-dd') + '  00:00:00';
        }
        if (payload?.end_date) {
          payload['end_date'] =
            formatDate(payload?.end_date, 'yyyy-mm-dd') + '  23:59:59';
        }
      }
      let resp = await getLogsListingExportFile(payload);
      if (resp) {
        downloadBlobXLSFile(resp?.data, `SearchLogs`);
      } else {
        toast.error('Something went wrong.');
      }
      setExpLoading(false);
    } catch (e) {
      console.log(e);
      setExpLoading(false);
    }
  };

  const actionBody = (data) => {
    return (
      <>
        <div style={{ display: 'flex' }}>
          <CustomButton
            className="noMinWidth buttonDense"
            onClick={() => {
              handleCofirmModalOpen(true);
              setDeleteLogsData(data);
            }}
            sx={{
              padding: '5px 10px',
              marginRight: 1,
              fontSize: '12px',
              bgcolor: 'buttonSecondary.main',
              color: 'buttonSecondary.contrastText',
            }}
          >
            <Delete />
          </CustomButton>
        </div>
      </>
    );
  };

  const handleCofirmModalOpen = (value) => {
    setConfirmDelete(value);
  };

  const handleCofirmAllModalOpen = (value) => {
    setConfirmAllDelete(value);
  };

  const handlePendingOrderDelete = async (data, resetState) => {
    const resp = await deleteLogs({ log_id: data });
    if (resp) {
      let payloadForLogs = {
        action: action?.delete,
        module: modulesNames?.logs,
        description: logsMessagesList?.searchLogs?.searchLogsDelete,
      };
      await adminAddLogs(payloadForLogs);
      getListing({ page: '1' });
      setPage(1);
      resetState([]);
    }
  };

  const handleAddtionalButtonFunction = () => {
    handleCofirmAllModalOpen(true);
  };

  return (
    <>
      <MatTable
        loading={allLogs?.loading}
        showCheckbox={false}
        columns={column}
        data={tableListing}
        onApplyFilter={(data = {}) => {
          getListing({
            page: String(1),
            start_date: '',
            end_date: '',
            ...getObjectSubset(data, 'start_date', 'end_date', 'sort_by'),
          });
          if (Object.keys(data).length > 0) {
            setAllParams({
              ...allParams,
              page: String(1),
              ...getObjectSubset(data, 'start_date', 'end_date', 'sort_by'),
            });
          } else {
            setAllParams({
              page: String(1),
            });
          }
        }}
        action={actionBody}
        page={page}
        perPage={limit}
        showPageLimit={true}
        pageLimitArray={[20, 50, 100, 200]}
        handleChangePageLimit={(e) => {
          setLimit(e.target.value);
          setAllParams({
            ...allParams,
            page: String('1'),
            limit: Number(e.target.value),
          });
        }}
        total={allLogs?.items?.totalCount || 0}
        checkboxEnable={true}
        customCheckboxDataKey={'search_id'}
        onTableToolbarRightClick={handlePendingOrderDelete}
        customLabel={'Delete'}
        onTableToolbarRightSection={
          <IconButton>
            <DeleteIcon />
          </IconButton>
        }
        setPage={setPage}
        handleSearch={(searchTerm) => {
          getListing({
            page: String(1),
            search: searchTerm,
          });
          setAllParams({
            ...allParams,
            page: String(1),
            search: searchTerm,
          });
        }}
        handlePageChange={(newPage) => {
          getListing({
            page: String(newPage),
          });
          setAllParams({
            ...allParams,
            page: String(newPage),
          });
        }}
        toolbarEnable={true}
        dropdownFilter={true}
        selectFilterArray={[
          {
            name: 'sort_by',
            label: 'Sort By',
            placeholder: 'Sort By',
            options: LogsSortData,
          },
        ]}
        pagination={true}
        filtersUrlData={allParams || {}}
        // filterEnable
        // rangeDateFilter
        customFilter={() => (
          <AllFilters
            filtersArray={filtersArray}
            handleClick={handleFilter}
            handleClear={handleClear}
            hasDownloadExcelButton={true}
            handleDownloadExcel={getExportFile}
            loading={loading}
            addionalButton={allLogs?.items?.totalCount > 0 ? true : false}
            addtionalText={'Bulk Delete'}
            handleAddtionalButton={handleAddtionalButtonFunction}
          />
        )}
        disableSearch
      />
      {confirmDelete && (
        <ConfirmationModal
          open={confirmDelete}
          handleConfirm={handleConfirmDelete}
          setOpen={handleCofirmModalOpen}
        />
      )}
      {confirmAllDelete && (
        <ConfirmationModal
          open={confirmAllDelete}
          handleConfirm={handleConfirmAllDelete}
          setOpen={handleCofirmAllModalOpen}
        />
      )}
    </>
  );
}

export default AllSearchLog;
