import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Grid, Typography } from '@mui/material';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { createFaq, editAddCMS, editFaq, getCMSInfo, getFAQSListing } from '../../Services/cmsService';
import TextEditor from '../CommonComp/TextEditor/TextEditor';
import CustomBox from '../CommonComp/CustomBox/CustomBox';
import { cmsItemsSelector } from '../../redux/cms';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';
import { adminAddLogs } from '../../Services/commonService';

function OfficeAddress() {
  const [loading, setLoading] = useState(false);
  const { cmsData } = useSelector(cmsItemsSelector);
  const dispatch = useDispatch();
  let cmsDataHome = cmsData ? cmsData?.items?.length > 0 && JSON?.parse(cmsData?.items[0]?.key_value) : [];

  useEffect(() => {
    getListing();
  }, [])

  const getListing = () => {
    let payload = { pagename: 'homePage' }
    dispatch(getCMSInfo(payload));
  }

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true)
    if (values.address) {
      let newString = values.address
      newString = newString.split('<p')
      if (newString?.length) {
        newString = newString.join(' <p style="margin: 0px" ')
      } else {
        newString = values.address
      }

      newString = newString.split('<br')
      if (newString?.length) {
        newString = newString.join(` <br style="display: block; content: ''; padding: 5px;" `)
      } else {
        newString = values.address
      }

      newString = newString.split('<a')
      if (newString?.length) {
        newString = newString.join(' <a class="table-vertical-bluetext-technical" ')
      } else {
        newString = values.address
      }

      let payload = {
        address: newString
      }
    let newObj = { ...cmsDataHome, addressSection: payload };
    setLoading(true);
    let str1 = JSON.stringify(newObj);
    let obj = { pagename: 'homePage', pagedata: str1 };
    let resp = await editAddCMS(obj);
    if (resp) {
      getListing();
      let payloadForLogs = {
        action: action?.update,
        module: modulesNames?.cms,
        description: logsMessagesList?.cms?.officeAddressUpdate
      };
      await adminAddLogs(payloadForLogs);
    }
    setLoading(false);
  };
  setLoading(false);
}

  const formik = useFormik({
    initialValues: {
      address:cmsDataHome?.addressSection?.address || ''
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      address: Yup.string().required('Description is required.'), 
    }),
    onSubmit: handleConfirm,
  });


  const handleChange = (content) => {
    if (content == '<p><br></p>') {
      formik.setFieldValue('address', '')
    } else {
      formik.setFieldValue('address', content)
    }
  }

  return (
    <CustomBox padding={1} >
      <Box
        noValidate
        component="form"
        onSubmit={formik.handleSubmit}
        marginTop={2}
        marginLeft={2}

      >
        <Grid container spacing={2}>
         
          <Grid item xs={12} md={12}>
            <Typography variant='h6' style={{ marginBottom: 3, paddingLeft: 3 }}>Office Address</Typography>
            <TextEditor content={formik.values.address} handleChange={handleChange}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
              modules={{
                toolbar: [
                  ["link"],
                  [{ color: ["red", "#785412", '#000'] }],
                  [{ background: ["red", '#fff', '#0000', "#785412"] }],
                  [{ list: "ordered" }, { list: "bullet" },{ align: ["right", "center", "justify"] }],
                  // [{ 'indent': '-1'}, { 'indent': '+1' }],
                  ["bold", "underline"],
                ]
              }}
              label="Description"
            />
          </Grid>
        
        </Grid>
        <div
          style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
        >
          <CustomButton
            color={'buttonPrimary'}
            type="submit"
            loading={loading}
          >
          Submit
          </CustomButton>
          <CustomButton
            onClick={() => {
              formik.resetForm({
                subject: '',
              });
              handleOpen(false)
            }
            }
            disabled={loading}
          >
            Cancel
          </CustomButton>
        </div>
      </Box>
    </CustomBox>
  );
}

export default OfficeAddress;
