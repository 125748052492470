import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import CustomInput from '../CommonComp/CustomInput/CustomInput';
import CustomButton from '../CommonComp/Buttons/CustomButton';
import { useDispatch } from 'react-redux';
import { editVideoPrice } from '../../Services/cmsService';
import { action, logsMessagesList, modulesNames } from '../../data/logsMessages';
import { adminAddLogs } from '../../Services/commonService';


function EditVideoPrice({ data, open, handleOpen, setPage ,handleLast}) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleConfirm = async (values, { resetForm }) => {
    setLoading(true);
    let resp = await editVideoPrice(values)
    if(resp){
      let payloadForLogs = {
        action: action?.update,
        module: modulesNames?.cms,
        description: logsMessagesList?.cms?.videoPricingUpdated
      };
      await adminAddLogs(payloadForLogs);
      resetForm({
        image_type_id:"",
        price :"",
        image_type :"",
        Dimensions :"",
        file_format: "",
        resolution: "",
      })
    //   setPage(1)
    //   // dispatch(subscriptionListings({
    //   //   page: String(1),
    //   //   limit: limit
    //   // }))
      handleLast();
      handleOpen(false)
    }
    setLoading(false)
  };

  const formik = useFormik({
    initialValues: {
      video_type_id: data?.video_type_id|| "",
      price : data?.price || "",
      video_type : data?.video_type|| "",
      Dimensions : data?.Dimensions || "",
      file_format: data?.file_format|| "",
      resolution: data?.resolution|| "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      video_type_id:Yup.string().required('Id is required.'),
      video_type: Yup.string().required('Image Type is required.'),
      price: Yup.string().required('Plan price is required.').matches(/^[0-9]+$/, "Must be only digits"),
      resolution: Yup.string().required('Resolution is required.'),
      file_format: Yup.string().required('File Format is required.'),
      Dimensions:Yup.string().required('Dimensions is required.'),
    }),
    onSubmit: handleConfirm,
  });

  return (
    <div>
      <Dialog
        maxWidth={'md'}
        open={open}
        onClose={() => {
          // handleOpen(false);
        }}
      >
        <DialogTitle variant="h3" padding={3}>
          Edit Video 
        </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            // sx={{
            //   width: 400
            // }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="video_type"
                  label="Video Type"
                  placeholder="Video Type"
                  value={formik.values.video_type}
                  select
                  SelectProps={{
                    native: true
                  }}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.video_type && Boolean(formik.errors.video_type)}
                  helperText={formik.touched.video_type && formik.errors.video_type}
                > 
                  <option key={'LARGE'} value={"LARGE"}>LARGE</option>
                  <option key={'SMALL'} value={"SMALL"}>SMALL</option>
                </CustomInput>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="Dimensions"
                  label="Dimensions"
                  placeholder="Dimensions"
                  value={formik.values.Dimensions}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.Dimensions && Boolean(formik.errors.Dimensions)}
                  helperText={formik.touched.Dimensions && formik.errors.Dimensions}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="file_format"
                  label="File Format"
                  placeholder="File Format"
                  value={formik.values.file_format }
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.file_format && Boolean(formik.errors.file_format)}
                  helperText={formik.touched.file_format && formik.errors.file_format }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="price"
                  label="Price"
                  placeholder="Price"
                  value={formik.values.price}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.price && Boolean(formik.errors.price)}
                  helperText={formik.touched.price && formik.errors.price}
                />
              </Grid>
            
              <Grid item xs={12} sm={6} md={4}>
                <CustomInput
                  variant="outlined"
                  fullWidth
                  name="resolution"
                  label="Resolution"
                  placeholder="Resolution"
                  value={formik.values.resolution }
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    classes: {
                      notchedOutline: 'rounded',
                    },
                  }}
                  error={formik.touched.resolution && Boolean(formik.errors.resolution)}
                  helperText={formik.touched.resolution && formik.errors.resolution }
                />
              </Grid>
            </Grid>
            <div
              style={{ display: 'flex', justifyContent: 'end', marginTop: 15 }}
            >
              <CustomButton
                color={'buttonPrimary'}
                type="submit"
                loading={loading}
              >
                Save
              </CustomButton>
              <CustomButton
                onClick={() => {
                    formik.resetForm({
                      image_type_id:  "",
                      price : "",
                      image_type :  "",
                      Dimensions : "",
                      file_format: "",
                      resolution: "",
                    });
                    handleOpen(false)}
                }
                disabled={loading}
              >
                Cancel
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
        {/* <DialogActions>
					<CustomButton color={'buttonPrimary'} type="submit" loading={loading}>Upload</CustomButton>
					<CustomButton onClick={() => handleOpen(false)}>Close</CustomButton>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}

export default EditVideoPrice;
